import {
    UniversalDataTable,
    UniversalModal,
    UniversalToolBar,
    UniversalCookieCrumb,
    UniversalLoadingCircle,
    DataGridLayout,
} from '../../UniversalComponents'
import { useReducer, useEffect, useContext } from 'react'

import { Grid, Typography } from '@mui/material'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import DealersForm from './DealersForm'
import {
    Archive as ArchiveIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import { getDealers, activateDealers, deleteDealers } from '../../../apiRoutes'
import { Authentication } from '../../../context/AuthenticationContext'

import { ToastMessage } from '../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../context/MinimizeContext'
import {
    standardColumnMapper,
    columnMapper,
    permissionsCheck,
    handleActivation,
    handleDeletion,
} from '../../../utilities/functionTools'
import { usePermissions, useAuth } from '../../../context/AuthenticationContext'

const today = new Date().toISOString().substring(0, 10)
const Dealers = () => {
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { userRole, activeManufacturer, username, userPreferences, _id } =
        currentUser.auth

    const defaultState = {
        open: false,
        formCache: {},
        row: {},
        data: [],
        activateData: [],
        viewActive: true,
        dealers: {
            columns: [
                {
                    field: 'dealerName',
                    headerName: 'NAME',
                    width: 150,
                },
                {
                    field: 'dealerAddress',
                    headerName: 'ADDRESS',
                    width: 150,
                },
                {
                    field: 'dealerEmail',
                    headerName: 'EMAIL',
                    width: 150,
                },
                {
                    field: 'dealerPhoneNumber',
                    headerName: 'PHONE NUMBER',
                    width: 150,
                },
            ],
            density: 'standard',
        },
        density:
            userPreferences?.dataTableColumns?.dealers?.density || 'standard',
    }
    const setToastMessage = useContext(ToastMessage)
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        add,
        data,
        activateData,
        viewActive,
        formCache,
        dealers,
        density,
    } = state

    const userPermissions = usePermissions()

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.dealerName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        getData(viewActive)
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.dealerName
                ? `Edit: ${formCache.dealerName}`
                : 'Add New Dealer',
            link: '/admin/Dealers',
            data: formCache.id
                ? { ...formCache, tab: 'dealer' }
                : { ...formCache, id: 'dealer', tab: 'dealer' },
            id: formCache.id ? formCache.id : 'dealer',
        })
        setState({ open: false })
    }

    const getData = async (active: boolean) => {
        try {
            const getDealersData = await getDealers(active)

            if (getDealersData && getDealersData.length !== 0) {
                const dealers = standardColumnMapper(
                    defaultColumns,
                    getDealersData
                )

                setState({
                    data: dealers,
                })
            } else {
                setState({
                    data: [],
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const dealers = userPreferences?.dataTableColumns?.dealers?.columns

        if (Array.isArray(dealers)) {
            const mapColumns = dealers.map((dealer) => ({
                field: dealer?.field,
                headerName: dealer?.headerName,
                width: dealer?.width,
                isVisible: dealer.isVisible,
            }))

            const format = {
                columns: mapColumns,
            }

            setState({ ...state, dealers: format })
        } else {
            console.log('No Dealers Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'dealers',
                value: dealers,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleArchiveData = async () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'dealerName',
            setState,
            getData,
            route: activateDealers,
            viewDeactivated: !viewActive,
            setToastMessage,
            page: 'dealers',
        })
    }
    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'dealerName',
            setState,
            getData,
            route: deleteDealers,
            viewDeactivated: !viewActive,
            setToastMessage,
            page: 'dealers',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Dealers?`,
            'warning',
            handleDeletionData
        )
    }
    const setView = () => setState({ viewActive: !viewActive })

    useEffect(() => {
        collectColumnData()
        getData(true)
    }, [])

    useEffect(() => {
        getData(viewActive)
    }, [viewActive, userRole, activeManufacturer])

    const defaultColumns = [
        'dealerName',
        'dealerAddress',
        'dealerEmail',
        'dealerPhoneNumber',
        'dealerSalesContactName',
        'dealerSalesContactEmail',
        'dealerSalesContactPhone',
        'dealerServiceContactName',
        'dealerServiceContactEmail',
        'dealerServiceContactPhone',
        'dealerLatitude',
        'dealerLongitude',
        'archived',
        'updatedBy',
        'updatedAt',
    ]

    const buttons = [
        {
            buttonName: 'Add New Dealer',
            buttonIcon: <AddBusinessIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Dealers'),
        },

        {
            buttonName: viewActive ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleArchiveData,
            disabled: permissionsCheck(userPermissions, 'Dealers'),
        },
        {
            buttonName: viewActive
                ? 'View Deactivated Dealers'
                : 'View Active Dealers',
            buttonIcon: <Restore />,
            buttonFunction: setView,
        },

        // {
        //     buttonName: 'Export',
        //     buttonIcon: <ArrowOutwardIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = { columns: format, density: setDensity }

        setState({
            dealers: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            dealers: {
                ...dealers,
                density: newDensity,
            },
        })
    }

    return (
        <>
            <DataGridLayout
                cookieCrumbs={<UniversalCookieCrumb />}
                toolbar={
                    <UniversalToolBar
                        buttons={
                            viewActive
                                ? buttons
                                : [
                                      ...buttons,
                                      {
                                          buttonName: 'Delete Dealers',
                                          buttonIcon: <DeleteForever />,
                                          buttonFunction: handleDeletionCheck,
                                      },
                                  ]
                        }
                    />
                }
                dataGrid={
                    <>
                        <UniversalLoadingCircle
                            data={data}
                            reloadFunction={
                                viewActive
                                    ? () => getData(true)
                                    : () => getData(false)
                            }
                            customTimeoutMessage={
                                !viewActive
                                    ? 'No Active Dealers Available'
                                    : 'No Deactivated Dealers Available'
                            }
                        >
                            <UniversalDataTable
                                data={data}
                                apiDensity={density}
                                apiColumns={dealers?.columns}
                                savePreferences={() => submitColumnData()}
                                visibleColumns={[]}
                                getRowData={(tableRow) =>
                                    setModalVisbility(tableRow, undefined)
                                }
                                onRowSelectionModelChange={(newSelection) => {
                                    setState({ activateData: newSelection })
                                }}
                                onUpdatedColumnsChange={
                                    handleUpdatedColumnsChange
                                }
                                onDensityChange={handleDensityChange}
                            />
                        </UniversalLoadingCircle>
                    </>
                }
            />

            <UniversalModal
                title={row.dealerName ? 'Edit New Dealer' : 'Add New Dealer '}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '95%',
                    marginTop: 5,
                }}
                maxWidth="lg"
                timeStamp={`${
                    row.dealerName
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <DealersForm
                    formTitle="Edit Dealer Details"
                    row={row}
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Dealers'
                    )}
                />
            </UniversalModal>
        </>
    )
}

export default Dealers
