import { useContext, useEffect, useReducer, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { Box, FormControl, LinearProgress, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { FormSection, SaveButton } from '../../UniversalComponents'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { addDevice } from '../../../apiRoutes'
import { getParts } from '../../../apiRoutes'
import responseError from '../../../context/responseError/responseError'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'

const DevicesForm = ({
    formTitle,
    row,
    onClose,
    saveCache,
    minimizeModal,
    permissionCheck,
    quickStart,
}) => {
    const defaultState = {
        formData: {
            deviceSerial: row?.deviceSerial || '',
            iccid: row?.iccid || '',
            part: row?.part?._id || '',
            active: row?.active || true,
            provider: row?.provider || '',
            id: row?._id || '',
            partNumber: row?.partNumber || '',
        },

        formCache: {},
        devices: [],
        parts: [],
        partOptions: [],
        selectedPart: '',
        boatOptions: [],
        partDescription: row.partDescription || '',
        permissionsCheck: permissionCheck,
        deviceSerialCharacterCount: '',

        formValidation: {
            deviceSerialValidation: false,
            iccidValidation: false,
            providerValidation: false,
            partNumberValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        displayData: {
            fullHin: row.fullHin || '',
            shortHin: row.shortHin || '',
            nickname: row.nickname || '',
        },
    }

    const reducer = (state, newState) => ({ ...state, ...newState })

    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)

    const {
        formData,
        formCache,
        parts,
        partOptions,
        formValidation,
        displayData,
        partDescription,
        permissionsCheck,
        deviceSerialCharacterCount,
    } = state
    const { id, deviceSerial, iccid, part, provider, partNumber } = formData

    const { fullHin, shortHin, nickname } = displayData

    const {
        deviceSerialValidation,
        iccidValidation,
        providerValidation,
        partNumberValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        if (name === 'partNumber') {
            const newPartNumberId = parts.find(
                (part) => part.partNumber === value
            )

            setState({
                partDescription: newPartNumberId
                    ? newPartNumberId.partDescription
                    : '',
                formData: {
                    ...formData,
                    part: newPartNumberId ? newPartNumberId._id : '',
                    partNumber: value,
                },
            })
        } else if (name === 'deviceSerial') {
            const characterCount = value
            setState({
                deviceSerialCharacterCount: characterCount,
                formData: { ...formData, [name]: value },
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }
        saveCache({ ...formData, [name]: value })
    }

    const handleSubmit = async () => {
        try {
            // Hard coded Osmosis as the manufacturer for now
            const parsedFormData = {
                ...formData,
                manufacturer: '667c16c741fd1a79fc434920',
            }

            let res: any

            res = await addDevice(parsedFormData)
            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Device Serial: ${data.deviceSerial} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )
                    onClose(row.deviceSerial, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        if (
            !deviceSerialValidation &&
            !iccidValidation &&
            !providerValidation &&
            !partNumberValidation &&
            isFormDirty &&
            deviceSerial &&
            iccid &&
            provider &&
            partNumber
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        deviceSerialValidation,
        iccidValidation,
        providerValidation,
        partNumberValidation,
        deviceSerial,
        iccid,
        provider,
        partNumber,
    ])

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (name === 'deviceSerial') {
            if (value && value.length === 12) {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: false,
                        isFormDirty: true,
                    },
                })
            } else {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: true,

                        isFormDirty: true,
                    },
                })
            }
        } else {
            if (value !== '') {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: false,
                        isFormDirty: true,
                    },
                })
            } else {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: true,
                        isFormDirty: true,
                    },
                })
            }
        }
    }

    const getData = async () => {
        let parts: any = []
        let partOptions: any = []
        let partDescription: any = ''

        try {
            const getPartData = await getParts()

            if (getPartData && getPartData.length !== 0) {
                partOptions = getPartData.map(({ partNumber }) => partNumber)
                parts = getPartData
            }
            if (row.part) {
                partDescription = parts.find(
                    (part) => part._id === row.part._id
                ).partDescription
                partDescription
            }
            setState({
                partOptions,
                parts,
                partDescription,
            })
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const providers = ['T-Mobile', 'Integron', 'AT&T']

    const handleReset = () => {
        setState({
            partDescription: defaultState.partDescription,
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
            deviceSerialCharacterCount: defaultState.deviceSerialCharacterCount,
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 1, marginTop: 3 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {row.deviceSerial
                        ? 'Use this form to Edit a telematics device within the Osmosis Telematics Platform.'
                        : 'Use this form to Create a telematics device within the Osmosis Telematics Platform.'}
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <FormSection
                            title="Device Information"
                            titleAlignment="center"
                        >
                            <Grid item xs={12} md={5}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Device Serial"
                                        name="deviceSerial"
                                        onBlur={validateOnBlur}
                                        error={
                                            deviceSerialValidation &&
                                            deviceSerial.length !== 12
                                        }
                                        value={deviceSerial}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        helperText={
                                            deviceSerialValidation &&
                                            deviceSerial.length !== 12
                                                ? 'Device Serial must be 12 characters long.'
                                                : ''
                                        }
                                        disabled={
                                            !!row.deviceSerial &&
                                            permissionsCheck
                                        }
                                        inputProps={{ maxLength: 12 }}
                                    />
                                    <Box>
                                        <Typography
                                            sx={{
                                                marginTop: 0.5,

                                                display:
                                                    deviceSerialCharacterCount
                                                        ? ''
                                                        : 'none',
                                            }}
                                            fontSize={'small'}
                                            color={
                                                deviceSerialCharacterCount.length ===
                                                12
                                                    ? 'primary'
                                                    : '#AEAEAE'
                                            }
                                        >
                                            {`${
                                                deviceSerialCharacterCount.length
                                            } / ${12} Characters`}
                                        </Typography>
                                    </Box>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField
                                    label="ICCID"
                                    name="iccid"
                                    value={iccid}
                                    onBlur={validateOnBlur}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    helperText={
                                        iccidValidation && !iccid
                                            ? 'Please Enter an ICCID Value'
                                            : ''
                                    }
                                    error={iccidValidation && !iccid}
                                    disabled={permissionsCheck}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Autocomplete
                                    disablePortal
                                    fullWidth
                                    disabled={permissionsCheck}
                                    value={provider}
                                    onBlur={validateOnBlur}
                                    options={providers}
                                    id="provider"
                                    onChange={(event, newValue) => {
                                        const data = {
                                            target: {
                                                name: 'provider',
                                                value:
                                                    typeof newValue === 'string'
                                                        ? newValue
                                                        : '',
                                            },
                                        }
                                        handleChange(data)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Provider"
                                            name="provider"
                                            value={provider}
                                            onChange={handleChange}
                                            required
                                            helperText={
                                                providerValidation && !provider
                                                    ? 'Please Enter a Provider'
                                                    : ''
                                            }
                                            error={
                                                providerValidation && !provider
                                            }
                                            fullWidth
                                            disabled={permissionsCheck}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Autocomplete
                                    disablePortal
                                    fullWidth
                                    disabled={permissionsCheck}
                                    onBlur={validateOnBlur}
                                    value={partNumber}
                                    options={partOptions}
                                    id="partNumber"
                                    onChange={(event, newValue) => {
                                        const data = {
                                            target: {
                                                name: 'partNumber',
                                                value:
                                                    typeof newValue === 'string'
                                                        ? newValue
                                                        : '',
                                            },
                                        }
                                        handleChange(data)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Part Number"
                                            value={partNumber}
                                            onChange={handleChange}
                                            name="partNumber"
                                            helperText={
                                                partNumberValidation &&
                                                !partNumber
                                                    ? 'Please Enter a Part Number'
                                                    : ''
                                            }
                                            error={
                                                partNumberValidation &&
                                                !partNumber
                                            }
                                            required
                                            disabled={permissionsCheck}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    label="Part Description"
                                    name="partDescription"
                                    value={partDescription}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </FormSection>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormSection
                            title={
                                row?.fullHin
                                    ? 'Device Linked to Boat'
                                    : 'This Device is not linked to any Boat'
                            }
                            titleAlignment="center"
                        >
                            {row?.deviceSerial && row?.fullHin ? (
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                label="Nickname"
                                                defaultValue={nickname}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                label="Full Hin"
                                                defaultValue={`${fullHin}`}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                label="Short Hin"
                                                defaultValue={`${shortHin}`}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sx={{ marginTop: 3 }}
                                >
                                    <center>
                                        <DirectionsBoatFilledIcon
                                            sx={{
                                                fontSize: 60,
                                            }}
                                        />
                                    </center>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        sx={{ marginTop: 5 }}
                                    >
                                        <LinearProgress variant="indeterminate" />
                                    </Grid>
                                </Grid>
                            )}
                        </FormSection>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <SaveButton
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    disabledSave={!isFormValid}
                    disabledReset={!isFormDirty}
                    quickStart={quickStart}
                />
            </Grid>
        </Grid>
    )
}

export default DevicesForm
