import {
    Grid,
    Typography,
    TextField,
    Button,
    styled,
    IconButton,
    Tooltip,
    Card,
    CardMedia,
    AccordionDetails,
    AccordionSummary,
    Accordion,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
} from '@mui/material'
import UniversalTagToolbar from '../../UniversalComponents/UniversalTagToolbar'
import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { Authentication } from '../../../context/AuthenticationContext'
import { getManufacturers } from '../../../apiRoutes'
import { addManufacturer } from '../../../apiRoutes'
import {
    FormSection,
    SaveButton,
    UniversalModal,
} from '../../UniversalComponents'
import responseError from '../../../context/responseError/responseError'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import PhotoIcon from '@mui/icons-material/Photo'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import CheckIcon from '@mui/icons-material/Check'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { UniversalAutoComplete } from '../../UniversalComponents/UniversalFormStyles'
import { parseFormWithTags } from '../../../utilities/functionTools'
import UniversalPdfViewer from '../../UniversalComponents/UniversalPdfViewer'
import { MuiColorInput } from 'mui-color-input'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

const ManufacturerForm = ({
    row,
    formDescription,
    onClose,
    permissionCheck,
}) => {
    const defaultState = {
        formData: {
            manufacturerName: row.manufacturerName || '',
            manufacturerContactName: row.manufacturerContactName || '',
            manufacturerContactEmail: row.manufacturerContactEmail || '',
            manufacturerType: row.manufacturerType || '',
            manufacturerLogo: row.manufacturerLogo || '',
            manufacturerVideo: row.manufacturerVideo || '',
            manufacturerEula: row.manufacturerEula || '',
            manufacturerPrivacyPolicy: row.manufacturerPrivacyPolicy || '',
            manufacturerPrimaryColor: row.manufacturerPrimaryColor || '',
            manufacturerSecondaryColor: row.manufacturerSecondaryColor || '',
            manufacturerTertiaryColor: row.manufacturerTertiaryColor || '',
            id: row.id || '',
        },
        formValidation: {
            manufacturerNameValidation: false,
            manufacturerContactNameValidation: false,
            manufacturerContactEmailValidation: false,
            manufacturerTypeValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        permissionsCheck: permissionCheck,
        logo: '',
        logoFile: {},
        loginVideo: '',
        loginVideoFile: {},
        eula: '',
        eulaFile: {},
        privacyPolicy: '',
        privacyFile: {},
        openEula: false,
        openPrivacyPolicy: false,
        openHelper: false,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })

    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)
    const { userRole } = currentUser.auth

    const {
        formData,
        formValidation,
        permissionsCheck,
        logo,
        logoFile,
        loginVideo,
        loginVideoFile,
        eula,
        privacyPolicy,
        privacyFile,
        eulaFile,
        openEula,
        openPrivacyPolicy,
        openHelper,
    } = state
    const {
        manufacturerName,
        manufacturerContactEmail,
        manufacturerContactName,
        manufacturerType,
        manufacturerLogo,
        manufacturerVideo,
        manufacturerEula,
        manufacturerPrivacyPolicy,
        manufacturerPrimaryColor,
        manufacturerSecondaryColor,
        manufacturerTertiaryColor,
    } = formData

    const {
        manufacturerContactNameValidation,
        manufacturerContactEmailValidation,
        manufacturerNameValidation,
        manufacturerTypeValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
        })
    }

    const handleSubmit = async () => {
        try {
            const newFormData = { ...formData }

            const selectedFiles = [
                logoFile,
                loginVideoFile,
                eulaFile,
                privacyFile,
            ]

            const parsedFormData = parseFormWithTags(newFormData, selectedFiles)
            let res: any

            {
                res = await addManufacturer(parsedFormData)
                if (res) {
                    const { data, status } = res

                    console.log(res, 'res')

                    if (data && status === 200) {
                        setToastMessage(
                            `Manufactuer: ${data.manufacturerName} was ${
                                row.id ? 'edited' : 'added'
                            } successfully`,
                            'success'
                        )

                        onClose(row.manufacturerName, data)
                    } else {
                        responseError(res, row)
                    }
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const getEngineManufacturerData = async () => {
        try {
            const engineOEM = await getManufacturers(true)

            if (engineOEM && engineOEM.length !== 0) {
                setState({ engineOEM })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    useEffect(() => {
        getEngineManufacturerData()
    }, [])

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (value !== '') {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: false,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: true,
                    isFormDirty: true,
                },
            })
        }
    }

    useEffect(() => {
        const emailValid = () => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(manufacturerContactEmail)
        }
        if (
            isFormDirty &&
            !manufacturerContactNameValidation &&
            !manufacturerContactEmailValidation &&
            !manufacturerNameValidation &&
            !manufacturerTypeValidation &&
            emailValid() &&
            manufacturerContactName &&
            manufacturerContactEmail &&
            manufacturerType &&
            manufacturerName
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        manufacturerNameValidation,
        manufacturerContactNameValidation,
        manufacturerContactEmailValidation,
        manufacturerTypeValidation,
        manufacturerContactEmail,
        manufacturerContactName,
        manufacturerType,
    ])

    const emailCheck = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(manufacturerContactEmail)
    }

    const manufacturerOptions = ['boat', 'engine']

    useEffect(() => {
        if (row) {
            setState({
                formData: {
                    ...formData,
                    manufacturerName: row.manufacturerName || '',
                    manufacturerContactName: row.manufacturerContactName || '',
                    manufacturerContactEmail:
                        row.manufacturerContactEmail || '',
                    manufacturerType: row.manufacturerType || '',
                    id: row.id || '',
                },
            })
        }
    }, [row])

    const handleFileSelect = (event, isImage = false, isVideo = false) => {
        if (event.target.files && event.target.files.length !== 0) {
            const file = event.target.files[0]

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }

            if (isImage) {
                reader(file, (err, res) => {
                    setState({
                        logo: res,
                        logoFile: file,
                        formValidation: {
                            ...formValidation,
                            isFormDirty: true,
                        },
                    })
                })
            } else if (isVideo) {
                reader(file, (err, res) => {
                    setState({
                        loginVideo: res,
                        loginVideoFile: file,
                        formValidation: {
                            ...formValidation,
                            isFormDirty: true,
                        },
                    })
                })
            } else {
                setState({
                    formValidation: { ...formValidation, isFormDirty: true },
                })
            }
        }
    }

    const handleEulaUpload = (event: any) => {
        if (event.target.files && event.target.files.length !== 0) {
            const file = event.target.files[0]
            ;(file as any).tag = 'eula'

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }
            reader(file, (err, res) => {
                setState({
                    eula: res,
                    eulaFile: file,
                    formValidation: {
                        ...formValidation,
                        isFormDirty: true,
                    },
                })
            })
        }
    }

    const handlePrivacyUpload = (event: any) => {
        if (event.target.files && event.target.files.length !== 0) {
            const file = event.target.files[0]
            ;(file as any).tag = 'privacy'

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }

            reader(file, (err, res) => {
                setState({
                    privacyPolicy: res,
                    privacyFile: file,
                    formValidation: {
                        ...formValidation,
                        isFormDirty: true,
                    },
                })
            })
        }
    }

    const handleColorChange = (name, value) => {
        setState({
            formData: {
                ...formData,
                [name]: value,
            },
            formValidation: {
                ...formValidation,
                isFormDirty: true,
            },
        })
    }

    const handleReset = () =>
        setState({
            logoFile: defaultState.logoFile,
            logo: defaultState.logo,
            loginVideo: defaultState.loginVideo,
            loginVideoFile: defaultState.loginVideoFile,
            eula: defaultState.eula,
            privacyPolicy: defaultState.privacyPolicy,
            eulaFile: defaultState.eulaFile,
            privacyFile: defaultState.privacyFile,
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const handleLogoReset = () =>
        setState({
            ...state,
            formData: {
                ...formData,
                manufacturerLogo: formData.manufacturerLogo,
            },
            logoFile: defaultState.logoFile,
            logo: defaultState.logo,
            formValidation: defaultState.formValidation,
        })

    const handleVideoReset = () =>
        setState({
            ...state,
            formData: {
                ...formData,
                manufacturerVideo: formData.manufacturerVideo,
            },
            loginVideo: defaultState.loginVideo,
            loginVideoFile: defaultState.loginVideoFile,
            formValidation: { ...formValidation },
        })

    const handleDocumentReset = () =>
        setState({
            ...state,
            formData: {
                ...formData,
                manufacturerPrivacyPolicy:
                    defaultState.formData.manufacturerPrivacyPolicy,
                manufacturerEula: defaultState.formData.manufacturerEula,
            },
            eula: defaultState.eula,
            privacyPolicy: defaultState.privacyPolicy,
            eulaFile: defaultState.eulaFile,
            privacyFile: defaultState.privacyFile,
            formValidation: defaultState.formValidation,
        })

    const handleColorReset = () =>
        setState({
            formData: {
                ...formData,
                manufacturerPrimaryColor:
                    defaultState.formData.manufacturerPrimaryColor,
                manufacturerSecondaryColor:
                    defaultState.formData.manufacturerSecondaryColor,
                manufacturerTertiaryColor:
                    defaultState.formData.manufacturerTertiaryColor,
            },
            formValidation: defaultState.formValidation,
        })

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 1 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {formDescription}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <UniversalTagToolbar />
            </Grid>

            <Grid item xs={12}>
                <FormSection
                    title="Manufacturer Details"
                    reset={
                        <Button onClick={() => setState({ openHelper: true })}>
                            <HelpOutlineIcon />
                        </Button>
                    }
                >
                    <Grid item xs={12} md={4}>
                        <TextField
                            required={true}
                            label="Manufacturer Name"
                            name="manufacturerName"
                            onChange={handleChange}
                            fullWidth
                            onBlur={validateOnBlur}
                            value={manufacturerName}
                            error={
                                manufacturerNameValidation && !manufacturerName
                            }
                            helperText={
                                manufacturerNameValidation && !manufacturerName
                                    ? 'Please enter a Enter A Manufacturer Name.'
                                    : ''
                            }
                            disabled={permissionsCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required={true}
                            label="Manufacturer Contact Name"
                            name="manufacturerContactName"
                            onChange={handleChange}
                            fullWidth
                            onBlur={validateOnBlur}
                            value={manufacturerContactName}
                            error={
                                manufacturerContactNameValidation &&
                                !manufacturerContactName
                            }
                            helperText={
                                manufacturerContactNameValidation &&
                                !manufacturerContactName
                                    ? 'Please enter a Manufacturer Contact Name.'
                                    : ''
                            }
                            disabled={permissionsCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required={true}
                            label="Manufacturer Contact Email"
                            name="manufacturerContactEmail"
                            onChange={handleChange}
                            fullWidth
                            onBlur={validateOnBlur}
                            value={manufacturerContactEmail}
                            error={
                                manufacturerContactEmailValidation === false &&
                                manufacturerContactEmail
                                    ? !emailCheck()
                                    : manufacturerContactEmailValidation &&
                                      !manufacturerContactEmail
                            }
                            helperText={
                                manufacturerContactEmailValidation === false &&
                                !manufacturerContactEmail
                                    ? manufacturerContactEmailValidation &&
                                      !manufacturerContactEmail
                                    : !emailCheck()
                                    ? 'Please enter a Manufacturer Contact Email.'
                                    : ''
                            }
                            disabled={permissionsCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <UniversalAutoComplete
                            options={manufacturerOptions}
                            required={true}
                            label="Manufacturer Type"
                            name="manufacturerType"
                            handleChange={handleChange}
                            fullWidth
                            onBlur={validateOnBlur}
                            value={manufacturerType}
                            error={
                                manufacturerTypeValidation && !manufacturerType
                            }
                            helperText={
                                manufacturerTypeValidation && !manufacturerType
                                    ? 'Please enter a Manufacturer Type.'
                                    : ''
                            }
                            disabled={
                                permissionsCheck || userRole !== 'Super Admin'
                            }
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: -1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormSection
                            size={12}
                            title="Manufacturer Logo"
                            reset={
                                <Tooltip title={'Reset Logo'}>
                                    <IconButton
                                        onClick={handleLogoReset}
                                        disabled={permissionCheck}
                                    >
                                        <RestartAltIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        >
                            <Grid item xs={12}>
                                <label htmlFor="raised-button-file">
                                    <center>
                                        {logo || manufacturerLogo ? (
                                            <Button component="label">
                                                <Tooltip
                                                    title={
                                                        logo || manufacturerLogo
                                                            ? 'Replace Logo'
                                                            : 'Upload Logo'
                                                    }
                                                >
                                                    <Card
                                                        sx={{
                                                            maxWidth: 345,
                                                        }}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            height="300"
                                                            image={
                                                                logo ||
                                                                manufacturerLogo
                                                            }
                                                            alt="Manfacturer Logo"
                                                        />
                                                        <VisuallyHiddenInput
                                                            onChange={(event) =>
                                                                handleFileSelect(
                                                                    event,
                                                                    true,
                                                                    false
                                                                )
                                                            }
                                                            type="file"
                                                            accept="image/*"
                                                            id='htmlFor="raised-button-file'
                                                            disabled={
                                                                permissionCheck
                                                            }
                                                        />
                                                    </Card>
                                                </Tooltip>
                                            </Button>
                                        ) : (
                                            <Tooltip title="Upload a Logo">
                                                <IconButton component="label">
                                                    <PhotoIcon
                                                        sx={{
                                                            fontSize: 100,
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        onChange={(event) =>
                                                            handleFileSelect(
                                                                event,
                                                                true,
                                                                false
                                                            )
                                                        }
                                                        type="file"
                                                        accept="image/*"
                                                        id="raised-button-file"
                                                        disabled={
                                                            permissionCheck
                                                        }
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </center>
                                </label>
                            </Grid>
                        </FormSection>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSection
                            title="
                                Manufacturer Login Video"
                            reset={
                                <Box>
                                    <Tooltip
                                        title={'Replace Current Login Video'}
                                    >
                                        <IconButton
                                            component="label"
                                            sx={{
                                                display:
                                                    loginVideo ||
                                                    manufacturerVideo
                                                        ? null
                                                        : 'none',
                                            }}
                                        >
                                            <VideoLibraryIcon
                                                sx={{
                                                    fontSize: 30,
                                                }}
                                            />
                                            <VisuallyHiddenInput
                                                onChange={(event) =>
                                                    handleFileSelect(
                                                        event,
                                                        false,
                                                        true
                                                    )
                                                }
                                                type="file"
                                                accept="video/*"
                                                id="raised-button-file-video"
                                                disabled={permissionCheck}
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Reset Login Video'}>
                                        <IconButton
                                            onClick={handleVideoReset}
                                            disabled={permissionCheck}
                                        >
                                            <RestartAltIcon
                                                sx={{ fontSize: 30 }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                        >
                            <Grid item xs={12} md={12}>
                                <label htmlFor="raised-button-file-video">
                                    <center>
                                        {loginVideo || manufacturerVideo ? (
                                            <Button component="label">
                                                <Card>
                                                    <CardMedia
                                                        component="video"
                                                        height="250"
                                                        controls
                                                        src={
                                                            loginVideo ||
                                                            manufacturerVideo
                                                        }
                                                    >
                                                        <source
                                                            src={
                                                                loginVideo ||
                                                                manufacturerVideo
                                                            }
                                                            type="video/mp4"
                                                        />
                                                        Your browser does not
                                                        support the video tag.
                                                        <VisuallyHiddenInput
                                                            onChange={(event) =>
                                                                handleFileSelect(
                                                                    event,
                                                                    false,
                                                                    true
                                                                )
                                                            }
                                                            type="file"
                                                            accept="video/*"
                                                            id="raised-button-file-video"
                                                            disabled={
                                                                permissionCheck
                                                            }
                                                        />
                                                    </CardMedia>
                                                </Card>
                                            </Button>
                                        ) : (
                                            <Tooltip
                                                title={'Upload Login Video'}
                                            >
                                                <IconButton component="label">
                                                    <VideoLibraryIcon
                                                        sx={{
                                                            fontSize: 100,
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        onChange={(event) =>
                                                            handleFileSelect(
                                                                event,
                                                                false,
                                                                true
                                                            )
                                                        }
                                                        type="file"
                                                        accept="video/*"
                                                        id="raised-button-file-video"
                                                        disabled={
                                                            permissionCheck
                                                        }
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </center>
                                </label>
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <FormSection
                            title="Manufacturer Documents"
                            spacing={1}
                            reset={
                                <Tooltip title={'Reset Documents'}>
                                    <IconButton
                                        onClick={handleDocumentReset}
                                        disabled={permissionCheck}
                                    >
                                        <RestartAltIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        >
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={
                                            <Tooltip
                                                title={'Show EULA Preview'}
                                            >
                                                <ExpandMoreIcon />
                                            </Tooltip>
                                        }
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button
                                                    component="label"
                                                    title={
                                                        manufacturerEula?.length !==
                                                        0
                                                            ? 'Replace Current EULA'
                                                            : 'Upload EULA'
                                                    }
                                                >
                                                    <DriveFolderUploadIcon
                                                        sx={{ fontSize: 40 }}
                                                    />

                                                    <Typography
                                                        variant="h5"
                                                        color={'primary'}
                                                        alignContent="center"
                                                        sx={{ marginLeft: 1 }}
                                                    >
                                                        {eula
                                                            ? eulaFile.name
                                                            : manufacturerEula
                                                            ? 'Replace Eula PDF'
                                                            : 'Upload EULA PDF'}
                                                        {eula ? (
                                                            <CheckIcon />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Typography>

                                                    <input
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={
                                                            handleEulaUpload
                                                        }
                                                        disabled={
                                                            permissionCheck
                                                        }
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                    />
                                                </Button>
                                            </Grid>
                                            {manufacturerEula && (
                                                <Grid item xs={6}>
                                                    <Button
                                                        onClick={() =>
                                                            setState({
                                                                openEula: true,
                                                            })
                                                        }
                                                        size="large"
                                                    >
                                                        <PictureAsPdfIcon
                                                            sx={{
                                                                fontSize: 40,
                                                            }}
                                                            color="primary"
                                                        />
                                                        <Typography
                                                            variant="h5"
                                                            alignContent="right"
                                                            sx={{
                                                                marginLeft: 1,
                                                            }}
                                                        >
                                                            View Eula Document
                                                        </Typography>
                                                    </Button>
                                                    <UniversalModal
                                                        open={openEula}
                                                        onClose={() => {
                                                            setState({
                                                                openEula: false,
                                                            })
                                                        }}
                                                    >
                                                        <UniversalPdfViewer
                                                            url={
                                                                manufacturerEula
                                                            }
                                                        />
                                                    </UniversalModal>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            sx={{ height: '100vh' }}
                                        >
                                            {eula ? (
                                                <iframe
                                                    title="EULA"
                                                    width="100%"
                                                    height="100%"
                                                    style={{
                                                        overflow: 'scroll',
                                                    }}
                                                    src={eula}
                                                />
                                            ) : (
                                                <Typography
                                                    color={'primary'}
                                                    variant="h5"
                                                >
                                                    No PDF Available For
                                                    Preview.
                                                </Typography>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={
                                            <Tooltip
                                                title={
                                                    'Show Privacy Policy Preview'
                                                }
                                            >
                                                <ExpandMoreIcon />
                                            </Tooltip>
                                        }
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button
                                                    component="label"
                                                    title={
                                                        manufacturerPrivacyPolicy?.length !==
                                                        0
                                                            ? 'Replace Current Privacy Policy'
                                                            : 'Upload Privacy Policy'
                                                    }
                                                >
                                                    <DriveFolderUploadIcon
                                                        sx={{ fontSize: 40 }}
                                                    />

                                                    <Typography
                                                        variant="h5"
                                                        color={'primary'}
                                                        alignContent="center"
                                                        sx={{ marginLeft: 1 }}
                                                    >
                                                        {privacyPolicy
                                                            ? privacyFile.name
                                                            : manufacturerPrivacyPolicy
                                                            ? 'Replace Privacy Policy'
                                                            : 'Upload Privacy Policy'}
                                                        {privacyPolicy ? (
                                                            <CheckIcon />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Typography>

                                                    <input
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={
                                                            handlePrivacyUpload
                                                        }
                                                        disabled={
                                                            permissionCheck
                                                        }
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                    />
                                                </Button>
                                            </Grid>
                                            {manufacturerPrivacyPolicy && (
                                                <Grid item xs={6}>
                                                    <Button
                                                        onClick={() =>
                                                            setState({
                                                                openPrivacyPolicy:
                                                                    true,
                                                            })
                                                        }
                                                        size="large"
                                                    >
                                                        <PictureAsPdfIcon
                                                            sx={{
                                                                fontSize: 40,
                                                            }}
                                                            color="primary"
                                                        />
                                                        <Typography
                                                            variant="h5"
                                                            alignContent="right"
                                                            sx={{
                                                                marginLeft: 1,
                                                            }}
                                                        >
                                                            View Privacy Policy
                                                            Document
                                                        </Typography>
                                                    </Button>
                                                    <UniversalModal
                                                        open={openPrivacyPolicy}
                                                        onClose={() => {
                                                            setState({
                                                                openPrivacyPolicy:
                                                                    false,
                                                            })
                                                        }}
                                                    >
                                                        <UniversalPdfViewer
                                                            url={
                                                                manufacturerPrivacyPolicy
                                                            }
                                                        />
                                                    </UniversalModal>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            sx={{ height: '100vh' }}
                                        >
                                            {privacyPolicy ? (
                                                <iframe
                                                    title="PrivacyPolicy"
                                                    width="100%"
                                                    height="100%"
                                                    style={{
                                                        overflow: 'scroll',
                                                    }}
                                                    src={privacyPolicy}
                                                />
                                            ) : (
                                                <Typography
                                                    color={'primary'}
                                                    variant="h5"
                                                >
                                                    No PDF Available For
                                                    Preview.
                                                </Typography>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </FormSection>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormSection
                            title="Manufacturer Color Palette"
                            spacing={1}
                            reset={
                                <Tooltip title={'Reset Color Palette'}>
                                    <IconButton
                                        onClick={handleColorReset}
                                        disabled={permissionCheck}
                                    >
                                        <RestartAltIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        >
                            <Grid item xs={12} padding={2}>
                                <MuiColorInput
                                    value={manufacturerPrimaryColor}
                                    onChange={(value) =>
                                        handleColorChange(
                                            'manufacturerPrimaryColor',
                                            value
                                        )
                                    }
                                    label="Primary Color"
                                    name="manufacturerPrimaryColor"
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12} padding={2}>
                                <MuiColorInput
                                    value={manufacturerSecondaryColor}
                                    onChange={(value) =>
                                        handleColorChange(
                                            'manufacturerSecondaryColor',
                                            value
                                        )
                                    }
                                    label="Secondary Color"
                                    name="manufacturerSecondaryColor"
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12} padding={2}>
                                <MuiColorInput
                                    value={manufacturerTertiaryColor}
                                    onChange={(value) =>
                                        handleColorChange(
                                            'manufacturerTertiaryColor',
                                            value
                                        )
                                    }
                                    label="Tertiary Color"
                                    name="manufacturerTertiaryColor"
                                    sx={{ width: '100%' }}
                                />
                            </Grid>

                            <Dialog
                                open={openHelper}
                                onClose={() => setState({ openHelper: false })}
                                maxWidth="sm"
                                fullWidth
                            >
                                <Box>
                                    <DialogTitle align="center">
                                        File Upload
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                            sx={{ marginTop: 2 }}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{ mr: 1 }}
                                                />
                                                <span>
                                                    A file marked for upload
                                                    cannot exceed{' '}
                                                    <strong>50 MB</strong>
                                                </span>
                                            </Box>
                                        </Typography>
                                    </DialogContent>
                                </Box>
                            </Dialog>
                        </FormSection>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <SaveButton
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    disabledSave={!isFormValid || permissionCheck}
                    disabledReset={!isFormDirty || permissionCheck}
                />
            </Grid>
        </Grid>
    )
}

export default ManufacturerForm
