import { useContext, useEffect, useReducer } from 'react'
import {
    FormControlLabel,
    Switch,
    Grid,
    TextField,
    styled,
    IconButton,
    Box,
    Tooltip,
    Chip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
} from '@mui/material'

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { datePickerConverter } from '../../../../../utilities/functionTools'
import { ToastMessage } from '../../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../../context/AuthenticationContext'
import { getManufacturers } from '../../../../../apiRoutes'
import { parseFormWithFile } from '../../../../../utilities/functionTools'
import UniversalTagToolbar from '../../../../UniversalComponents/UniversalTagToolbar'
import { FormSection, SaveButton } from '../../../../UniversalComponents'
import responseError from '../../../../../context/responseError/responseError'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { basicValidator } from '../../../../../utilities/functionTools'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import { UniversalAutoComplete } from '../../../../UniversalComponents/UniversalFormStyles'

const EngineCalibrationForm = ({
    row,
    onClose,
    quickStart,
    addCalibrationFile,
}) => {
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth

    const defaultState = {
        formData: {
            id: row?.id ? row?.id : `${Math.random()}`,
            engineCalibrationECMPartNumber:
                row?.engineCalibrationECMPartNumber || '',
            engineCalibrationFileUrl: row?.engineCalibrationFileUrl || '',
            engineCalibrationFileName: row?.engineCalibrationFileName || '',
            engineCalibrationProductionDate:
                row?.engineCalibrationProductionDate
                    ? datePickerConverter(row?.engineCalibrationProductionDate)
                    : '',
            engineCalibrationRevision: row?.engineCalibrationRevision || '',
            engineCalibrationEControlsSoftwareRevision:
                row?.engineCalibrationEControlsSoftwareRevision || '',
            engineCalibrationSoftwareModel:
                row?.engineCalibrationSoftwareModel || '',
            engineCalibrationChecksum: row?.engineCalibrationChecksum || '',
            engineCalibrationReleaseNotes:
                row?.engineCalibrationReleaseNotes || '',
            engineCalibrationApproved: row?.engineCalibrationApproved || false,
            engineCalibrationActive: row?.engineCalibrationActive || false,
            engineCalibrationSeverityLevel:
                row?.engineCalibrationSeverityLevel || '',
        },

        formValidation: {
            engineCalibrationChecksumValiation: false,
            engineCalibrationSeverityLevelValidation: false,
            engineCalibrationProductionDateValidation: false,
            engineCalibrationFileNameValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        selectedFile: {},
        setVersionNameInput: '',
        closeModal: false,
        calibrationFileNameDisabled: row.calibrationFileName ? true : false,
        manufacturerChoiceDisabled: row.manufacturerName ? true : false,
        manufacturerOptions: [],
        manufacturers: [],
        shortChecksum:
            row?.engineCalibrationChecksum?.length === 4 ? true : false,
        checksumCharacterCount: '',
        open: false,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        selectedFile,
        formValidation,
        manufacturers,
        shortChecksum,
        checksumCharacterCount,
        open,
    } = state

    const {
        engineCalibrationECMPartNumber,
        engineCalibrationFileUrl,
        engineCalibrationFileName,
        engineCalibrationProductionDate,
        engineCalibrationRevision,
        engineCalibrationEControlsSoftwareRevision,
        engineCalibrationSoftwareModel,
        engineCalibrationChecksum,
        engineCalibrationReleaseNotes,
        engineCalibrationApproved,
        engineCalibrationActive,
        engineCalibrationSeverityLevel,
    } = formData

    const {
        engineCalibrationChecksumValidation,
        engineCalibrationProductionDateValidation,
        engineCalibrationSeverityLevelValidation,
        engineCalibrationFileNameValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const getEngineManufacturerData = async () => {
        try {
            const data = await getManufacturers(true)
            if (data && data.length !== 0) {
                setState({
                    manufacturerOptions: data
                        .filter(
                            (manufacturer) => manufacturer.type === 'engine'
                        )
                        .map(({ manufacturerName }) => manufacturerName),
                    manufacturers: data.filter(
                        (manufacturer) => manufacturer.type === 'engine'
                    ),
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const handleSubmit = async () => {
        delete formData.updatedAt
        const calibrationFile = [selectedFile]
        const newFormData = { ...formData, updatedBy: username }

        const parsedFormData = parseFormWithFile(newFormData, calibrationFile)

        addCalibrationFile(newFormData, parsedFormData)

        onClose(newFormData)
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === 'manufacturerName') {
            const newEngineManufacturerId = manufacturers.find(
                (file) => file.manufacturerName === value
            )

            setState({
                formData: {
                    ...formData,
                    manufacturer: newEngineManufacturerId
                        ? newEngineManufacturerId._id
                        : '',
                },
            })
        } else if (name === 'engineCalibrationChecksum') {
            const characterCount = value
            setState({
                checksumCharacterCount: characterCount,
                formData: { ...formData, [name]: value },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        }
    }

    const handleFileSelect = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]

            setState({
                selectedFile: file,
                formValidation: { ...formValidation, isFormDirty: true },
                formData: {
                    ...formData,
                    engineCalibrationFileName: file?.name,
                },
            })
        }
    }

    const handleFileRemove = () => {
        setState({
            selectedFile: [],
        })
    }

    const handleReset = () => {
        if (row.calibrationFileName) {
            setState({
                selectedFile: { name: row.calibrationFileName || undefined },
                formData: defaultState.formData,
                formValidation: defaultState.formValidation,
                checksumCharacterCount: defaultState.checksumCharacterCount,
                shortChecksum: defaultState.shortChecksum,
            })
        } else {
            setState({
                formData: defaultState.formData,
                formValidation: defaultState.formValidation,
                selectedFile: '',
                checksumCharacterCount: defaultState.checksumCharacterCount,
                shortChecksum: defaultState.shortChecksum,
            })
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })

    useEffect(() => {
        getEngineManufacturerData()
    }, [])

    useEffect(() => {
        if (
            isFormDirty &&
            !engineCalibrationFileNameValidation &&
            !engineCalibrationSeverityLevelValidation &&
            !engineCalibrationChecksumValidation &&
            !engineCalibrationProductionDateValidation &&
            engineCalibrationFileName &&
            engineCalibrationChecksum &&
            engineCalibrationSeverityLevel &&
            engineCalibrationProductionDate
        ) {
            if (
                (shortChecksum && engineCalibrationChecksum.length === 4) ||
                (!shortChecksum && engineCalibrationChecksum.length === 8)
            ) {
                setState({
                    formValidation: {
                        ...formValidation,
                        isFormValid: true,
                    },
                })
            } else if (
                (!shortChecksum && engineCalibrationChecksum.length !== 8) ||
                (shortChecksum && engineCalibrationChecksum.length !== 4)
            ) {
                setState({
                    formValidation: {
                        ...formValidation,
                        isFormValid: false,
                    },
                })
            }
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        engineCalibrationFileNameValidation,
        engineCalibrationSeverityLevelValidation,
        engineCalibrationChecksumValidation,
        engineCalibrationProductionDateValidation,
        engineCalibrationFileName,
        engineCalibrationChecksum,
        engineCalibrationSeverityLevel,
        engineCalibrationProductionDate,
        shortChecksum,
    ])

    const severityOptions = [
        '0 - Nonspecific',
        '1 - Suggested',
        '2 - Recommended',
        '3 - Required',
    ]

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <FormSection title="Engine Calibration File ">
                    <Grid item xs={12}>
                        <TextField
                            name="engineCalibrationFileName"
                            label="EMOT File Name"
                            value={engineCalibrationFileName}
                            fullWidth
                            onChange={handleChange}
                            disabled={true}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={5}>
                <FormSection
                    title="Engine Calibration File Upload"
                    reset={
                        <Button onClick={() => setState({ open: true })}>
                            <HelpOutlineIcon />
                        </Button>
                    }
                >
                    <Grid item xs={12} sx={{ paddingBottom: 0.7 }}>
                        <label htmlFor="raised-button-file">
                            <Tooltip
                                title={
                                    engineCalibrationFileUrl ? (
                                        <h3 style={{ color: 'primary' }}>
                                            Replace Calibration File
                                        </h3>
                                    ) : (
                                        <h3 style={{ color: 'inherit' }}>
                                            Upload Calibration File
                                        </h3>
                                    )
                                }
                            >
                                <center>
                                    {engineCalibrationFileUrl ? (
                                        <IconButton component="label">
                                            <Box>
                                                <UploadFileIcon
                                                    sx={{
                                                        fontSize: 90,
                                                    }}
                                                />
                                                <VisuallyHiddenInput
                                                    onChange={handleFileSelect}
                                                    type="file"
                                                    accept=".emot"
                                                    id='htmlFor="raised-button-file'
                                                />
                                            </Box>
                                        </IconButton>
                                    ) : (
                                        <IconButton component="label">
                                            <DriveFolderUploadIcon
                                                sx={{
                                                    fontSize: 90,
                                                }}
                                            />
                                            <VisuallyHiddenInput
                                                onChange={handleFileSelect}
                                                type="file"
                                                accept=".emot"
                                                id='htmlFor="raised-button-file'
                                            />
                                        </IconButton>
                                    )}
                                </center>
                            </Tooltip>
                        </label>
                    </Grid>
                    <Grid item xs={12}>
                        {selectedFile?.name && (
                            <Chip
                                label={selectedFile?.name}
                                variant="outlined"
                                color="info"
                                icon={
                                    <FilePresentIcon
                                        sx={{
                                            color: 'primary',
                                        }}
                                    />
                                }
                                onDelete={() => {
                                    handleFileRemove()
                                    setState({
                                        formData: {
                                            ...formData,
                                            engineCalibrationFileName:
                                                defaultState.formData
                                                    .engineCalibrationFileName,
                                        },
                                        formValidation: {
                                            ...formValidation,
                                            isFormDirty: true,
                                        },
                                    })
                                }}
                                sx={{
                                    color: 'grey',
                                    fontSize: 25,
                                    borderRadius: 0,
                                }}
                                deleteIcon={<RemoveCircleIcon />}
                            />
                        )}
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: -2 }}>
                <FormSection title="Deployment & Production">
                    <Grid item xs={12} md={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Production Date"
                                views={['year', 'month', 'day']}
                                value={engineCalibrationProductionDate}
                                onChange={({ $d }) => {
                                    const event = {
                                        target: {
                                            value: $d.toDateString(),
                                            name: 'engineCalibrationProductionDate',
                                        },
                                    }
                                    setState({
                                        formValidation: {
                                            ...formValidation,
                                            isFormDirty: true,
                                        },
                                    })
                                    handleChange(event)
                                }}
                                onAccept={() => {
                                    // Validate when a date is selected
                                    basicValidator(
                                        {
                                            target: {
                                                name: 'engineCalibrationProductionDate',
                                                value: engineCalibrationProductionDate,
                                            },
                                        },
                                        formValidation,
                                        setState
                                    )
                                }}
                                onClose={() => {
                                    // Validate when the date picker closes
                                    basicValidator(
                                        {
                                            target: {
                                                name: 'engineCalibrationProductionDate',
                                                value: engineCalibrationProductionDate,
                                            },
                                        },
                                        formValidation,
                                        setState
                                    )
                                }}
                                sx={{ width: '100%' }}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        helperText:
                                            engineCalibrationProductionDateValidation &&
                                            !engineCalibrationProductionDate
                                                ? 'Production Date is required'
                                                : '',
                                        error:
                                            engineCalibrationProductionDateValidation &&
                                            !engineCalibrationProductionDate,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <UniversalAutoComplete
                            label="Severity Level"
                            required={true}
                            handleChange={handleChange}
                            options={severityOptions}
                            name="engineCalibrationSeverityLevel"
                            value={engineCalibrationSeverityLevel}
                            onBlur={(e: any) =>
                                basicValidator(e, formValidation, setState)
                            }
                            helperText={
                                engineCalibrationSeverityLevelValidation &&
                                !engineCalibrationSeverityLevel
                                    ? 'Severity Level is required'
                                    : ''
                            }
                            error={
                                engineCalibrationSeverityLevelValidation &&
                                !engineCalibrationSeverityLevel
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={9}>
                                <TextField
                                    name="engineCalibrationChecksum"
                                    required={true}
                                    label="Checksum"
                                    value={engineCalibrationChecksum}
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{
                                        maxLength: shortChecksum ? 4 : 8,
                                    }}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    helperText={
                                        engineCalibrationChecksumValidation &&
                                        !engineCalibrationChecksum
                                            ? 'Checksum is Required.'
                                            : ''
                                    }
                                    error={
                                        engineCalibrationChecksumValidation &&
                                        !engineCalibrationChecksum
                                    }
                                />
                                <Box>
                                    <Typography
                                        sx={{
                                            marginTop: 0.5,

                                            display: checksumCharacterCount
                                                ? ''
                                                : 'none',
                                        }}
                                        fontSize={'small'}
                                        color={
                                            shortChecksum
                                                ? checksumCharacterCount.length ===
                                                  4
                                                    ? 'primary'
                                                    : '#AEAEAE'
                                                : checksumCharacterCount.length ===
                                                  8
                                                ? 'primary'
                                                : '#AEAEAE'
                                        }
                                    >
                                        {shortChecksum
                                            ? `${
                                                  checksumCharacterCount.length
                                              } / ${4} Characters`
                                            : `${
                                                  checksumCharacterCount.length
                                              } / ${8} Characters`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() => {
                                                const { shortChecksum } = state
                                                const {
                                                    engineCalibrationChecksum,
                                                } = formData

                                                let updatedChecksum =
                                                    engineCalibrationChecksum.substring(
                                                        0,
                                                        4
                                                    )

                                                setState({
                                                    shortChecksum:
                                                        !shortChecksum,
                                                    formData: {
                                                        ...formData,
                                                        engineCalibrationChecksum:
                                                            updatedChecksum,
                                                    },
                                                    formValidation: {
                                                        ...formValidation,
                                                        isFormDirty: true,
                                                    },
                                                    checksumCharacterCount:
                                                        updatedChecksum,
                                                })
                                            }}
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                            id="switch.shortChecksum"
                                            name="switch.shortChecksum"
                                            checked={shortChecksum}
                                            key="switch.shortChecksum"
                                        />
                                    }
                                    label="Short Checksum"
                                    key="switch.shortChecksum"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            name="engineCalibrationECMPartNumber"
                            label="ECM Part Number"
                            value={engineCalibrationECMPartNumber}
                            fullWidth
                            onChange={(event) => {
                                const newValue = event.target.value
                                const data = {
                                    target: {
                                        name: 'engineCalibrationECMPartNumber',
                                        value:
                                            typeof newValue === 'string'
                                                ? newValue
                                                : '',
                                    },
                                }
                                handleChange(data)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                        <TextField
                            name="engineCalibrationSoftwareModel"
                            label="Software Model"
                            value={engineCalibrationSoftwareModel}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            name="engineCalibrationRevision"
                            label="Rev"
                            value={engineCalibrationRevision}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                        <TextField
                            name="engineCalibrationEControlsSoftwareRevision"
                            label="Econtrols Software Revision"
                            value={engineCalibrationEControlsSoftwareRevision}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() =>
                                        setState({
                                            formData: {
                                                ...formData,
                                                engineCalibrationActive:
                                                    !engineCalibrationActive,
                                            },
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                        })
                                    }
                                    inputProps={{
                                        'aria-label': 'controlled',
                                    }}
                                    id="switch.engineCalibrationActive"
                                    name="switch.engineCalibrationActive"
                                    checked={engineCalibrationActive}
                                    key="switch.engineCalibrationActive"
                                />
                            }
                            label="Engine Calibration Active"
                            key="switch.engineCalibrationActive"
                            disabled={engineCalibrationActive}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextField
                            name="engineCalibrationReleaseNotes"
                            label="Release Notes"
                            value={engineCalibrationReleaseNotes}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                </FormSection>

                <Dialog
                    open={open}
                    onClose={() => setState({ open: false })}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle align="center">
                        Calibration File Upload
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            <Box display="flex" alignItems="center">
                                <FiberManualRecordIcon sx={{ mr: 1 }} />
                                Upload a Calibration File by clicking on the
                                icon.
                            </Box>
                        </Typography>

                        <Typography
                            variant="body1"
                            gutterBottom
                            sx={{ marginTop: 2 }}
                        >
                            <Box display="flex" alignItems="center">
                                <FiberManualRecordIcon sx={{ mr: 1 }} />
                                <span>
                                    A Calibration File is{' '}
                                    <strong>required</strong> for the form to
                                    submit.
                                </span>
                            </Box>
                        </Typography>
                    </DialogContent>
                </Dialog>

                <Grid item xs={12} md={12} sx={{ marginTop: 5 }}>
                    <SaveButton
                        title="Required Fields Are Marked With An *"
                        titleAlignment="center"
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid}
                        disabledReset={!isFormDirty}
                        quickStart={quickStart}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EngineCalibrationForm
