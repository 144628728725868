import { useReducer, useState, useEffect, useContext, useCallback } from 'react'
import {
    UniversalDataTable,
    UniversalModal,
    UniversalToolBar,
    UniversalLoadingCircle,
    UniversalCookieCrumb,
    DataGridLayout,
    FormSection,
} from '../../UniversalComponents'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { Grid, Typography } from '@mui/material'
import EngineManagerForm from './EngineManagerForm'
import {
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    AddToQueue as AddToQueueIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import {
    getEngines,
    deactivateEngines,
    deleteEngines,
    deleteBlobCalibration,
} from '../../../apiRoutes'
import { useMinimizer } from '../../../context/MinimizeContext'
import { usePermissions, useAuth } from '../../../context/AuthenticationContext'
import {
    permissionsCheck,
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../utilities/functionTools'

const today = new Date().toISOString().substring(0, 10)

const Engines = () => {
    const setToastMessage = useContext(ToastMessage)
    const userPermissions = usePermissions()
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { userRole, username, activeManufacturer, userPreferences, _id } =
        currentUser.auth

    const defaultState = {
        open: false,
        row: {},
        data: [],
        viewDeactivated: false,
        formCache: {},
        activateData: [],
        etag: '',
        title: '',
        blobUrl: '',
        engines: {
            engine: {
                columns: [
                    {
                        field: 'engineIdentifier',
                        headerName: 'ENGINE IDENTIFIER',
                        width: 400,
                    },
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 300,
                    },

                    {
                        field: 'engineName',
                        headerName: 'ENGINE NAME',
                        width: 150,
                    },
                    {
                        field: 'engineCalibrationFileName',
                        headerName: 'Active Calibration File',
                        width: 150,
                    },
                    {
                        field: 'engineDescription',
                        headerName: 'DESCRIPTION',
                        width: 150,
                    },

                    {
                        field: 'updatedBy',
                        headerName: 'UPDATED BY',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.engines?.engine?.density ||
            'standard',
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        data,
        activateData,
        viewDeactivated,
        formCache,
        engines,
        density,
        etag,
        title,
        blobUrl,
    } = state

    const { engine } = engines

    const getData = async (active) => {
        try {
            const data = await getEngines(active)

            if (data && data.length !== 0) {
                const engines = standardColumnMapper(defaultColumns, data)

                setState({ data: engines })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const engines =
            userPreferences?.dataTableColumns?.engines?.engine?.columns

        if (Array.isArray(engines)) {
            const mapColumns = engines.map((engine) => ({
                field: engine?.field,
                headerName: engine?.headerName,
                width: engine?.width,
                isVisible: engine?.isVisible,
            }))

            const format = { engine: { columns: mapColumns } }

            setState({ ...state, engines: format })
        } else {
            console.log('No Engines Found')
        }
    }

    const submitColumnData = async () => {
        try {
            setUser({
                key: 'engines',
                value: engines,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'engineName',
            setState,
            getData,
            route: deactivateEngines,
            viewDeactivated,
            setToastMessage,
            page: 'engines',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'engineName',
            setState,
            getData,
            route: deleteEngines,
            viewDeactivated,
            setToastMessage,
            page: 'engines',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Engines?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)
        collectColumnData()
    }, [])

    // useEffect(() => {
    //     console.log('DataGrid component mounted')
    //     return () => {
    //         submitColumnData()
    //         console.log('DataGrid component unmounted')
    //     }
    // }, []) this might be added later as a quality of life adjustment for userface ease

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const defaultColumns = [
        'engineName',
        'engineDescription',
        'updatedBy',
        'manufacturer.manufacturerName',
        'activeEngineCalibrationFile.engineCalibrationFileName',
        'engineIdentifier',
    ]

    const visibleColumns = [
        'engineHardwarePartNumber',
        'engineName',
        'engineDescription',
        'updatedBy',
        'manufacturerName',
        'engineCalibrationFileName',
    ]

    const customColumnNames = [
        'Engine Name',
        'Description',
        'Updated By',
        'Manufacturer',
        'Engine Identifier',
        'Active Calibration File',
    ]

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.id) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
        if (newData !== undefined) {
            return getData(true)
        }
    }

    const buttons = [
        {
            buttonName: 'Add New Engine',
            buttonIcon: <AddToQueueIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Engines'
                : 'View Deactivated Engines',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
        // {
        //     buttonName: 'Export',
        //     buttonIcon: <ArrowOutwardIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = {
            engine: { ...engine, columns: format, density: setDensity },
        }

        setState({
            engines: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            engines: {
                engine: {
                    ...engine,
                    density: newDensity,
                },
            },
        })
    }

    const handleBlobSetState = (blobInfo) => {
        setState({
            etag: blobInfo?.etag,
            title: blobInfo?.title,
            blobUrl: blobInfo?.url,
        })
    }

    const restBlob = () => {
        setState({ etag: '', blobUrl: '', title: '' })
    }

    const deleteBlob = async () => {
        const creatingData = {
            etag: state.etag,
            containerName: getContainerNameFromUrl(state.blobUrl),
            title: state.title,
        }

        const deletionData = { ...creatingData }

        let res
        try {
            res = await deleteBlobCalibration(deletionData)
            if (res && res.status === 200) {
                const { data } = res

                setToastMessage(`Calibration: ${data?.message}`, 'success')

                setState({ open: false })
                restBlob()
                getData(!viewDeactivated)
            } else {
                setToastMessage(
                    'Failed to delete the calibration file',
                    'error'
                )
            }
        } catch (error) {
            setToastMessage(
                'Error occurred while deleting the calibration file',
                'error'
            )
            console.error('Error deleting blob:', error)
        }
    }

    const getContainerNameFromUrl = (url) => {
        const regex =
            /https:\/\/[a-zA-Z0-9-]+\.blob\.core\.windows\.net\/([a-zA-Z0-9-]+)/
        const match = url.match(regex)
        return match ? match[1] : null
    }

    const handleBlobDeletion = (open) => {
        if (open === true) {
            setToastMessage(
                `Unsaved changes detected, exiting will undo any recent Engine Calibration File changes.`,
                'warning',
                () => {
                    deleteBlob()
                }
            )
        } else {
            console.log('Cancellation confirmed')
        }
    }

    return (
        <Grid container spacing={2}>
            <DataGridLayout
                cookieCrumbs={<UniversalCookieCrumb />}
                toolbar={<UniversalToolBar buttons={buttons} />}
                tabBar={
                    <FormSection>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                color={'primary'}
                                align="center"
                                sx={{ marginTop: -1 }}
                            >
                                {!viewDeactivated
                                    ? 'Engines'
                                    : 'Deactivated Engines'}
                            </Typography>
                        </Grid>
                    </FormSection>
                }
                dataGrid={
                    <UniversalLoadingCircle
                        data={data}
                        reloadFunction={!viewDeactivated ? getData : () => {}}
                        customTimeoutMessage={
                            !!viewDeactivated &&
                            'No Deactivated Engines Available'
                        }
                    >
                        <UniversalDataTable
                            data={data}
                            apiDensity={density}
                            apiColumns={engines?.engine?.columns}
                            savePreferences={() => submitColumnData()}
                            visibleColumns={[]}
                            customColumnNames={customColumnNames}
                            getRowData={(tableRow) =>
                                setModalVisbility(tableRow, undefined)
                            }
                            onRowSelectionModelChange={(newSelection) => {
                                setState({
                                    activateData: newSelection,
                                })
                            }}
                            onUpdatedColumnsChange={handleUpdatedColumnsChange}
                            onDensityChange={handleDensityChange}
                        />
                    </UniversalLoadingCircle>
                }
            />
            <UniversalModal
                title={`${
                    row.engineIdentifier
                        ? `Engine Identifier : ${row.engineIdentifier}`
                        : 'Configure A New Engine'
                }`}
                timeStamp={`${
                    row.engineIdentifier
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={
                    title.length !== 0 &&
                    blobUrl.length !== 0 &&
                    etag.length !== 0
                        ? () => {
                              handleBlobDeletion(open)
                          }
                        : (tableRow) => {
                              setModalVisbility(tableRow, undefined)
                          }
                }
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
            >
                <EngineManagerForm
                    row={row}
                    onClose={(tableRow: any, newData: any) => {
                        setModalVisbility(tableRow, newData)
                        restBlob()
                    }}
                    saveCache={(data: any) => setState({ formCache: data })}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Engines'
                    )}
                    quickStart={false}
                    blobInfo={handleBlobSetState}
                />
            </UniversalModal>
        </Grid>
    )
}

export default Engines
