import { useReducer, useEffect, useContext } from 'react'

import {
    UniversalModal,
    UniversalDataTable,
    UniversalLoadingCircle,
    UniversalToolBar,
    FormSection,
} from '../../../UniversalComponents'
import { Grid, Typography } from '@mui/material'

import {
    Archive as ArchiveIcon,
    Restore,
    DeleteForever,
    NotificationAdd as NotificationAddIcon,
} from '@mui/icons-material/'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import { Socket } from '../../../../context/SocketContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    deactivateSignalEvents,
    deleteSignalEvent,
    getEvents,
} from '../../../../apiRoutes'
import EventManagerForm from './SignalEventManagerForm'
import {
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'
import { permissionsCheck } from '../../../../utilities/functionTools'

const today = new Date().toISOString().substring(0, 10)

const SignalEventManager = ({ restoredData }) => {
    const defaultColumnVisibility = {
        'Notification Name': true,
        'Notification Urgency': true,
        'Signal Name': true,
        'Operator 1': true,
        'Value 1': true,
        'Logic 1 :': true,
        'Operator 2': true,
        'Value 2': true,
        Delivery: true,
    }

    const userPermissions = usePermissions()

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        serviceNotifications: [],
        columnVisibility: defaultColumnVisibility,
        reset: false,
        openBoatInsights: false,
        telemetryData: undefined,
        formCache: {},
        filteredEvents: [],
        events: [],
        viewDeactivated: false,
        activateData: [],
        data: [],
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        open,
        row,
        columnVisibility,
        formCache,
        filteredEvents,
        events,
        viewDeactivated,
        data,
        activateData,
    } = state

    const setToastMessage = useContext(ToastMessage)
    const { setMinimized } = useMinimizer()
    const currentUser = useContext(Authentication)
    const { username, userRole, activeManufacturer } = currentUser.auth

    const getData = async (active) => {
        try {
            const signalEvents = await getEvents(active)
            if (signalEvents && signalEvents.length !== 0) {
                const events = standardColumnMapper(
                    defaultColumns,
                    signalEvents
                )

                setState({ data: events })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'notificationName',
            setState,
            getData,
            route: deactivateSignalEvents,
            viewDeactivated,
            setToastMessage,
            page: 'signalEvents',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'notificationName',
            setState,
            getData,
            route: deleteSignalEvent,
            viewDeactivated,
            setToastMessage,
            page: 'signalEvents',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Models?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.notificationName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
        if (newData !== undefined) {
            return getData(!viewDeactivated)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.NotificationName
                ? `Edit: ${formCache.NotificationName}`
                : 'Add New Signal Event',
            link: '/data-hub/signals',
            data: formCache.id
                ? { ...formCache, tab: 'events' }
                : {
                      ...formCache,
                      id: 'newEvent',
                      tab: 'events',
                  },
            id: formCache.id ? formCache.id : 'newEvent',
        })
        setState({ open: false })
    }

    const setColumnHeaders = (columnHeader, reset?: boolean) => {}

    const defaultColumns = [
        'notificationName',
        'signal.signalFriendlyName',
        'manufacturer.manufacturerName',
        'notificationConditions',
        'notifyWeb',
        'notifyEmail',
        'notifyEmailList',
        'notificationFrequency',
        'signal._id',
        'signal.unitOfMeasurement.unitOfMeasurement',
        'updatedBy',
        'updatedAt',
        'User.userEmail',
        'archived',
    ]

    const visibleColumns = [
        'notificationName',
        'signalFriendlyName',
        'manufacturerName',
    ]

    const customColumnNames = ['Event Name', 'Signal Name', 'Manufacturer']

    const buttons = [
        {
            buttonName: 'Add New Signal Event',
            buttonIcon: <NotificationAddIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Signal Events'
                : 'View Deactivated Signal Events',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },

        // {
        //     buttonName: 'Reset Table',
        //     buttonIcon: <Restore />,
        //     buttonFunction: () => {
        //         setState({ columnVisibility: defaultColumnVisibility })
        //         setColumnHeaders(defaultColumnVisibility, true)
        //     },
        // },
        // {
        //     buttonName: 'Archive',
        //     buttonIcon: <ArchiveIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Active Signal Events'
                                : 'Deactivated Signal Events'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !!viewDeactivated &&
                        'No Deactivated Signal Events Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        visibleColumns={visibleColumns}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        getColumnHeaderVisibility={(columnHeader) =>
                            setColumnHeaders(columnHeader)
                        }
                        columnsAvailable={columnVisibility}
                        customColumnNames={customColumnNames}
                        onRowSelectionModelChange={(newSelection) => {
                            setState({
                                activateData: newSelection,
                            })
                        }}
                    />
                </UniversalLoadingCircle>
            </Grid>
            <UniversalModal
                title={
                    row.notificationName
                        ? `Editing Signal Event: ${row.notificationName}`
                        : 'Add New Event Notification'
                }
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                timeStamp={`${
                    row.updatedBy
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                onMinimize={minimizeModal}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 10,
                    marginBottom: 5,
                }}
                draggable={true}
                minimizable={true}
            >
                <EventManagerForm
                    row={row}
                    formDescription="Enter Boat Details To Add A New Boat."
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Signals'
                    )}
                />
            </UniversalModal>
        </Grid>
    )
}
export default SignalEventManager
