import { Grid, Typography } from '@mui/material'
import {
    FormSection,
    UniversalLoadingCircle,
    UniversalModal,
    UniversalToolBar,
} from '../../../UniversalComponents'
import UniversalDataTable from '../../../UniversalComponents/UniversalDataTable'
import { useContext, useEffect, useReducer } from 'react'
import SignalFormulaManagerForm from './SignalFormulaManagerForm'
import {
    Sensors as SensorsIcon,
    Archive as ArchiveIcon,
    Restore,
    Delete as DeleteIcon,
    Margin as MarginIcon,
} from '@mui/icons-material'
import { useMinimizer } from '../../../../context/MinimizeContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import {
    getSignalFormulas,
    activateSignalFormulas,
    deleteSignalFormulas,
} from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import {
    standardColumnMapper,
    handleActivation,
    handleDeletion,
} from '../../../../utilities/functionTools'
import { permissionsCheck } from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'

const today = new Date().toISOString().substring(0, 10)

const SignalFormulaManager = ({ restoredData }) => {
    const reducer = (state, newState) => ({ ...state, ...newState })
    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        formCache: {},
        data: [],
        viewDeactivated: false,
        activateData: [],
    }

    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)

    const { open, row, formCache, data, viewDeactivated, activateData } = state

    const { setMinimized } = useMinimizer()
    const currentUser = useContext(Authentication)
    const { username, userRole, activeManufacturer } = currentUser.auth

    const userPermissions = usePermissions()

    const getData = async (active: boolean) => {
        try {
            const getSignalFormulaData = await getSignalFormulas(active)

            if (getSignalFormulaData && getSignalFormulaData.length !== 0) {
                const dataMapping = standardColumnMapper(
                    defaultColumns,
                    getSignalFormulaData
                )

                setState({
                    data: dataMapping,
                })
            } else {
                setState({
                    data: [],
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'signalFormulaName',
            setState,
            getData,
            route: activateSignalFormulas,
            viewDeactivated,
            setToastMessage,
            page: 'signalFormulas',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'signalFormulaName',
            setState,
            getData,
            route: deleteSignalFormulas,
            viewDeactivated,
            setToastMessage,
            page: 'signalFormulas',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these signals?`,
            'warning',
            handleDeletionData
        )
    }

    useEffect(() => {
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.signalFriendlyName
                ? `Edit: ${formCache.signalFriendlyName}`
                : 'Add New Signal',
            link: '/data-hub/signals',
            data: formCache.id
                ? { ...formCache, tab: 'signals' }
                : { ...formCache, id: 'newSignal', tab: 'signals' },
            id: formCache.id ? formCache.id : 'newSignal',
        })
        setState({ open: false })
    }

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?._id) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        getData(!viewDeactivated)
    }

    const setViewDeactivated = () =>
        setState({ viewDeactivated: !viewDeactivated })

    const buttons = [
        {
            buttonName: 'Add New Signal Formula',
            buttonIcon: <MarginIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: !viewDeactivated
                ? 'View Deactivated Signal Formulas'
                : 'View Active Signal Formulas',
            buttonIcon: <Restore />,
            buttonFunction: setViewDeactivated,
        },
    ]

    const defaultColumns = [
        'signalFormulaName',
        'signalFormulaType',
        'updatedAt',
        'manufacturer.manufacturerName',
    ]

    const visibleColumns = [
        'signalFormulaName',
        'signalFormulaType',
        'updatedAt',
        'manufacturerName',
    ]

    const customColumnNames = [
        'Formula Name',
        'Formula Type',
        'Updated At',
        'Manufacturer',
    ]

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar
                    buttons={
                        !viewDeactivated
                            ? buttons
                            : [
                                  ...buttons,
                                  {
                                      buttonName: 'Delete Signal Formulas',
                                      buttonIcon: <DeleteIcon />,
                                      buttonFunction: () =>
                                          handleDeletionCheck(),
                                  },
                              ]
                    }
                />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Active Signal Formulas'
                                : 'Deactivated Signal Signal Formulas'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !viewDeactivated
                            ? 'No Active Signal Formulas Available'
                            : 'No Deactivated Signal Formulas Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        visibleColumns={visibleColumns}
                        customColumnNames={customColumnNames}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(rowSelection: any) => {
                            setState({ activateData: rowSelection })
                        }}
                    />
                </UniversalLoadingCircle>
            </Grid>

            <UniversalModal
                title={`${
                    row.signalFormulaName
                        ? ` ${row.signalFormulaName}`
                        : 'Signal Formula Manager'
                }`}
                timeStamp={`${
                    row.updatedBy
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <SignalFormulaManagerForm
                    row={row}
                    formDescription={
                        row?.signalFormulaName
                            ? 'Use this form to edit a Signal Formula within Osmosis Telematics Platform'
                            : 'Use this form to add a new Signal Formula within Osmosis Telematics Platform'
                    }
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Signals'
                    )}
                />
            </UniversalModal>
        </Grid>
    )
}

export default SignalFormulaManager
