import boatRoutes from './boatRoutes'
import userRoutes from './userRoutes'
import dealerRoutes from './dealerRoutes'
import deviceRoutes from './deviceRoutes'
import engineRoutes from './engineRoutes'
import engineCalibrationFileRoutes from './engineCalibrationRoutes'
import boatModelRoutes from './boatModelRoutes'
import manufacturerRoutes from './manufacturers'
import engineModelRoutes from './engineModelRoutes'
import firebaseRoutes from './firebaseRoutes'
import partRoutes from './partRoutes'
import signalRoutes from './signalRoutes'
import measurementRoutes from './unitOfMeasurementRoutes'
import signalEventManagerRoutes from './signalEventManagerRoutes'
import signalListRoute from './universalSignalListRoutes'

export const { getBoats, addBoat, editBoat, deleteBoat, deactivateBoats } =
    boatRoutes
export const { getDealers, addDealer, activateDealers, deleteDealers } =
    dealerRoutes

export const {
    getDevices,
    deactivateDevices,
    addDevice,
    deleteDevice,
    addMultipleDevices,
} = deviceRoutes
export const { getUsers, activateUsers, getUser, editUser } = userRoutes

export const {
    getEngines,
    addEngine,
    deleteEngines,
    deactivateEngines,
    addCalibrationFile,
    deleteBlobCalibration,
} = engineRoutes
export const {
    getEngineCalibrationFiles,
    addEngineCalibrationFile,
    archiveEngineCalibrationFiles,
    editEngineCalibrationFiles,
    deleteEngineCalibration,
    deactivateEngineCalibrationFiles,
} = engineCalibrationFileRoutes
export const {
    getBoatModels,
    addBoatModel,
    editBoatModels,
    deleteBoatModel,
    deactivateBoatModels,
    cloneBoatModels,
} = boatModelRoutes

export const {
    getManufacturers,
    addManufacturer,
    deleteManufacturer,
    deactivateManufacturers,
} = manufacturerRoutes

export const {
    getEngineModels,
    addEngineModel,
    deleteEngineModel,
    deactivateEngineModels,
} = engineModelRoutes

export const { createUser } = firebaseRoutes

export const { getParts } = partRoutes

export const {
    addSignal,
    getSignals,
    editSignal,
    activateSignals,
    deleteSignals,
} = signalRoutes

export const {
    addMeasurement,
    getMeasurement,
    editMeasurement,
    deleteMeasurements,
    deactivateMeasurements,
} = measurementRoutes

export const {
    getEvents,
    addEvent,
    deleteSignalEvent,
    deactivateSignalEvents,
} = signalEventManagerRoutes

export const { getSignalList } = signalListRoute
export { getAuthentication } from './authenticationRoutes'
export { getSignalOrder, postSignalOrder } from './signalOrderRoutes'
export {
    getUserNotificationSubscriptions,
    editUserNotificationSubscriptions,
} from './userNotificationSubscriptionsRoutes'
export {
    getSignalProfiles,
    postSignalProfile,
    activateSignalProfiles,
    deleteSignalProfiles,
    cloneSignalProfiles,
} from './signalProfileManagerRoutes'

export {
    getSignalFormulas,
    postSignalFormula,
    activateSignalFormulas,
    deleteSignalFormulas,
} from './signalFormulaRoutes'

/// Developer routes
export {
    getPatchNotes,
    addPatchNote,
    deactivatePatchNote,
    deletePatchNote,
    getLatestPatchNotes,
} from './patchNotes'

/// Monitor Routes

export {
    pingAPI,
    pingMongo,
    pingRedis,
    pingSocket,
} from './systemMonitorRoutes'

/// Longterm Telemetry Routes

export { getTelemetry, getBoatTelemetry } from './telemetryRoutes'
