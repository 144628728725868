import dayjs from 'dayjs'

export const datePickerConverter = (dateString) => {
    if (dateString) {
        if (dateString?.$d) {
            return dayjs(dateString.$d.toString().substring(0, 10))
        } else {
            return dayjs(dateString.substring(0, 10))
        }
    } else {
        return dayjs('L')
    }
}

export const parseFormWithFile = (data: any, files: any) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => body.append(`${key}`, data[key]))

    files.forEach((file) => body.append('file', file))

    console.log(body.getAll('file'), 'Files')
    return body
}

export const parseFormWithTags = (data: any, files: any) => {
    const body = new FormData()

    Object.keys(data).forEach((key) => body.append(`${key}`, data[key]))

    files.forEach((file) => {
        if (file.name) {
            const newFileName = file.tag
                ? `${file.tag}-${file.name}`
                : file.name

            const newFile = new File([file], newFileName, {
                type: file.type,
                lastModified: file.lastModified,
            })

            body.append('file', newFile)
        }
    })

    console.log(body.getAll('file'), 'Files')
    return body
}

export const excelFileParser = (data: any, file: File | null) => {
    const body = new FormData()

    // Append all form data key-value pairs to FormData
    Object.keys(data).forEach((key) => {
        body.append(key, data[key])
    })

    // Append the file (if present)
    if (file) {
        body.append('file', file)
    }

    console.log(body.getAll('file'), 'Files')
    return body
}

export const convertShortDate = (date: Date) => {
    const convertedDateString = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${
        date.getMinutes().toString().length === 1
            ? `0${date.getMinutes()}`
            : `${date.getMinutes()}`
    }`
    return convertedDateString
}

export const basicValidator = ({ target }, formValidation, setState) => {
    const { name, value } = target

    if (name && formValidation && setState) {
        if (value !== '' || value !== null) {
            return setState({
                formValidation: {
                    ...formValidation,
                    [`${name}Validation`]: false,
                    isFormDirty: true,
                },
            })
        } else {
            return setState({
                formValidation: {
                    ...formValidation,
                    [`${name}Validation`]: true,
                    isFormDirty: true,
                },
            })
        }
    }
}

export const standardColumnMapper = (defaultColumns: any, data: any[]) => {
    if (data && data.length !== 0) {
        const sanitizedDataArray = data.map((item) => {
            const sanitizedData: any = {}
            defaultColumns.forEach((column) => {
                let value: any = item
                const keys = column.split('.')

                keys.forEach((key) => {
                    if (value && typeof value === 'object' && key in value) {
                        value = value[key]
                    } else {
                        value = undefined
                        return
                    }
                })
                let columnName = keys[keys.length - 1]

                sanitizedData[columnName] = value
            })
            return { ...sanitizedData, id: item._id, ...item }
        })

        return sanitizedDataArray
    }
    return []
}

export const columnMapper = (defaultColumns: string[], data: any[]) => {
    if (data && data.length !== 0) {
        const sanitizedDataArray = data.map((item, index) => {
            const sanitizedData: any = {}
            defaultColumns.forEach((column) => {
                let value: any = item
                const keys = column.split('.')
                let columnPath = ''
                let lastKey = ''
                keys.forEach((key) => {
                    if (value && typeof value === 'object' && key in value) {
                        value = value[key]
                        columnPath += columnPath ? `.${key}` : key
                        lastKey = key
                    } else {
                        return value
                    }
                })

                let columnName = keys[keys.length - 1]

                lastKey = lastKey.replace(/_/g, '')

                if (
                    sanitizedData[columnName] !== undefined ||
                    columnName.includes('_')
                ) {
                    columnName = `${keys[0]}${lastKey}`
                }

                sanitizedData[columnName] = value
            })
            return { ...sanitizedData, id: item._id, ...item }
        })

        return sanitizedDataArray
    }
    return []
}

export const permissionsCheck = (userPermissions: any, component: string) => {
    const { userRole } = userPermissions
    if (userRole !== 'Super Admin') {
        if (userRole !== 'Admin') {
            if (
                userPermissions[component] &&
                userPermissions[component].write &&
                userPermissions[component].write === true
            ) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    } else {
        return false
    }
}

export const navPermissionsCheck = (userPermissions: any, component: any) => {
    const { userRole, manufacturer } = userPermissions
    if (userRole !== 'Super Admin') {
        if (userRole !== 'Admin') {
            if (
                userPermissions[component] &&
                userPermissions[component].read &&
                userPermissions[component].read === true
            ) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    } else {
        return false
    }
}
interface handleActivateProps {
    activateData: string[]
    data: []
    returnKey: string
    viewDeactivated: boolean
    setState: (e: any) => void
    getData: (e: boolean) => void
    route: any
    setToastMessage: any
    page: string
}
export const handleActivation = async ({
    activateData,
    data,
    returnKey,
    viewDeactivated,
    setState,
    getData,
    route,
    setToastMessage,
    page,
}: handleActivateProps) => {
    try {
        let res: any
        if (activateData.length !== 0) {
            const findDataToActivate = data
                .map((item: any) => {
                    if (activateData.includes(item._id)) {
                        return { _id: item._id, [returnKey]: item?.[returnKey] }
                    }
                })
                .filter((item) => item !== undefined)

            if (!viewDeactivated) {
                res = await route({
                    [page]: findDataToActivate,
                    activate: false,
                })
            } else {
                res = await route({
                    [page]: findDataToActivate,
                    activate: true,
                })
            }

            if (res && res.data && res.status === 200) {
                setToastMessage(
                    `${
                        !viewDeactivated ? 'Deactivated' : 'Activated'
                    } ${page}: ${res.data.map((item: string) => ` ${item}`)}`
                )
                setState({ activateData: [] })
                return getData(!viewDeactivated)
            }
        } else {
            setToastMessage(
                `No ${page} selected to ${
                    !viewDeactivated ? 'deactivate' : 'activate'
                }`,
                'error'
            )
        }
    } catch (error: any) {
        console.error('Error deactivating data:', error)
        setToastMessage(error?.message, 'error')
    }
}

interface handleDeletionProps {
    activateData: string[]
    data: []
    returnKey: string
    viewDeactivated: boolean
    setState: (e: any) => void
    getData: (e: boolean) => void
    route: any
    setToastMessage: any
    page: string
}
export const handleDeletion = async ({
    activateData,
    data,
    returnKey,
    viewDeactivated,
    setState,
    getData,
    route,
    setToastMessage,
    page,
}: handleDeletionProps) => {
    try {
        let res: any
        if (activateData.length !== 0) {
            const findDataToActivate = data
                .map((item: any) => {
                    if (activateData.includes(item._id)) {
                        return { _id: item._id, [returnKey]: item?.[returnKey] }
                    }
                })
                .filter((item) => item !== undefined)

            if (!viewDeactivated) {
                res = await route({
                    [page]: findDataToActivate,
                    activate: false,
                })
            } else {
                res = await route({
                    [page]: findDataToActivate,
                    activate: true,
                })
            }

            if (res && res.data && res.status === 200) {
                setToastMessage(
                    `Deleted ${page}: ${res.data.map(
                        (item: string) => ` ${item}`
                    )}`
                )
                setState({ activateData: [] })
                return getData(!viewDeactivated)
            }
        } else {
            setToastMessage(`No ${page} selected to Delete`, 'error')
        }
    } catch (error: any) {
        console.error('Error deactivating data:', error)
        setToastMessage(error?.message, 'error')
    }
}

interface handleCloneProps {
    cloneData: string[]
    data: []
    returnKey: string
    viewDeactivated: boolean
    setState: (e: any) => void
    getData: (e: boolean) => void
    route: any
    setToastMessage: any
    page: string
}
export const handleClone = async ({
    cloneData,
    data,
    returnKey,
    viewDeactivated,
    setState,
    getData,
    route,
    setToastMessage,
    page,
}: handleCloneProps) => {
    try {
        let res: any
        if (cloneData.length !== 0) {
            const findDataToActivate = data
                .map((item: any) => {
                    if (cloneData.includes(item._id)) {
                        const clonedItem = {
                            [returnKey]: item?.[returnKey],
                            ...item,
                        }
                        delete clonedItem._id
                        return clonedItem
                    }
                })
                .filter((item) => item !== undefined)

            if (!viewDeactivated) {
                res = await route({
                    [page]: findDataToActivate,
                    activate: false,
                })
            } else {
                res = await route({
                    [page]: findDataToActivate,
                    activate: true,
                })
            }

            if (res && res.data && res.status === 200) {
                setToastMessage(
                    `Cloned ${page}: ${res.data.map(
                        (item: string) => ` ${item}`
                    )}`
                )
                setState({ activateData: [] })
                return getData(!viewDeactivated)
            }
        } else {
            setToastMessage(`No ${page} selected to clone.`, 'error')
        }
    } catch (error: any) {
        console.error('Error cloning data:', error)
        setToastMessage(error?.message, 'error')
    }
}

export const parseTelemetryData = (telemetryData: any) => {
    let parsedTelemetryData: any = []
    if (telemetryData && telemetryData.length !== 0) {
        parsedTelemetryData = telemetryData.map((dataSet: any) => {
            const parsedJSON = JSON.parse(dataSet.data)
            if (Object.keys(parsedJSON).length !== 0) {
                return {
                    ...dataSet,
                    data: parsedJSON,
                }
            }
        })
    }
    return parsedTelemetryData
}

export const parseTelemetryDataColumns = (telemetryData) => {
    let defaultColumns: any = []
    if (telemetryData && telemetryData?.length !== 0) {
        telemetryData.map((dataSet) => {
            const parsedJSON = JSON.parse(dataSet.data)
            if (Object.keys(parsedJSON).length !== 0) {
                defaultColumns.push(...Object.keys(parsedJSON))
                return defaultColumns
            }
        })
        defaultColumns = new Set(defaultColumns.map((column) => column))
        defaultColumns = ['fullHin', 'timestamp', ...defaultColumns]
    }

    return defaultColumns
}

export const parseTelemetryDataGrid = (telemetryData: any) => {
    let parsedTelemetryData: any = []
    if (telemetryData && telemetryData.length !== 0) {
        parsedTelemetryData = telemetryData.map((dataSet: any) => {
            const parsedJSON = JSON.parse(dataSet.data)
            if (
                Object.keys(parsedJSON).length !== 0 &&
                Object.keys(dataSet.boat).length
            ) {
                return {
                    timestamp: dataSet?.timestamp,
                    id: `${dataSet?.boat?._id}-${Math.random()}`,
                    fullHin: dataSet?.boat?.fullHin,
                    manufacturer: dataSet?.boat?.manufacturer?.manufacturerName,
                    ...parsedJSON,
                }
            }
        })
    }
    return parsedTelemetryData
}
