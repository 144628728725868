import {
    Grid,
    Typography,
    TextField,
    Button,
    IconButton,
    Tooltip,
    styled,
    Card,
    CardMedia,
} from '@mui/material'
import {
    UniversalTagToolbar,
    UniversalModal,
    PDFViewer,
    FormSection,
    SaveButton,
} from '../../../UniversalComponents'
import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import {
    addBoatModel,
    getManufacturers,
    getSignalProfiles,
} from '../../../../apiRoutes'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import responseError from '../../../../context/responseError/responseError'
import { UniversalAutoComplete } from '../../../UniversalComponents/UniversalFormStyles'
import { parseFormWithFile } from '../../../../utilities/functionTools'

const BoatModelForm = ({
    row,
    formDescription,
    saveCache,
    onClose,
    permissionCheck,
    quickStart,
}) => {
    const currentUser = useContext(Authentication)
    const { userRole, activeManufacturer } = currentUser.auth
    const defaultState = {
        formData: {
            manufacturer: row?.manufacturer?.manufacturerName || '',
            modelYear: row?.modelYear || '',
            model: row?.model || '',
            modelImageUrl: row?.modelImageUrl || '',
            modelOwnersManualUrl: row?.modelOwnersManualUrl || '',
            id: row?.id || '',
            signalProfile: row?.signalProfile || { profileName: '' },
        },
        formValidation: {
            manufacturerValidation: false,
            modelYearValidation: false,
            modelValidation: false,
            signalProfileValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        modelImageFile: {},
        modelImage: '',
        modelOwnerManualFileType: '',
        modelOwnerManualFile: {},
        modelOwnerManualFileName: '',
        manufacturerOptions: [],
        manufacturers: [],
        permissionsCheck: permissionCheck,
        openManual: false,
        signalProfilesList: [],
        signalProfiles: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })

    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)

    const {
        formData,
        formValidation,
        manufacturers,
        manufacturerOptions,
        permissionsCheck,
        modelImage,
        modelImageFile,
        modelOwnerManualFile,
        modelOwnerManualFileType,
        modelOwnerManualFileName,
        openManual,
        signalProfilesList,
        signalProfiles,
    } = state

    const {
        manufacturerValidation,
        modelYearValidation,
        modelValidation,
        signalProfileValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const {
        manufacturer,
        model,
        modelYear,
        modelImageUrl,
        modelOwnersManualUrl,
        signalProfile,
    } = formData

    const handleChange = (e: any) => {
        const { name, value } = e.target

        if (name === 'signalProfile') {
            const selectProfile = signalProfiles.find(
                ({ profileName }) => profileName === value
            )
            setState({
                formData: {
                    ...formData,
                    signalProfile: selectProfile,
                },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }
        saveCache({ ...formData, [name]: value })
    }

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (value !== '') {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: false,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: true,
                    isFormDirty: true,
                },
            })
        }
    }

    const handleSubmit = async () => {
        try {
            const findManufacturer = manufacturers.find(
                ({ manufacturerName }) => manufacturerName === manufacturer
            )

            let res: any
            const newFormData = {
                ...formData,
                manufacturer: findManufacturer?._id,
                signalProfile: signalProfile?._id,
            }

            const selectedFiles = [modelImageFile, modelOwnerManualFile]

            const parsedFormData: any = parseFormWithFile(
                newFormData,
                selectedFiles
            )

            res = await addBoatModel(parsedFormData)

            if (res) {
                const { data, status } = res
                if (data && status === 200) {
                    setToastMessage(
                        `Boat Model: ${data.model} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )

                    onClose(row.model, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const handleFileSelect = (event: any, image?: boolean) => {
        if (event.target.files && event.target.files.length !== 0) {
            const file = event.target.files[0]

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }

            if (image) {
                reader(file, (err, res) => {
                    setState({
                        modelImage: res,
                        modelImageFile: file,
                        formValidation: {
                            ...formValidation,
                            isFormDirty: true,
                        },
                    })
                })
            } else {
                setState({
                    modelOwnerManualFile: file,
                    modelOwnerManualFileName: file.name,
                    modelOwnerManualFileType: file.type,
                    formValidation: { ...formValidation, isFormDirty: true },
                })
            }
        }
    }

    const handleFileRemove = () => {
        setState({
            selectedFiles: [],
            selectedFileImage: '',
        })
    }
    const getData = async () => {
        let manufacturerOptions: any = []
        let manufacturers: any = []
        let signalProfiles: any = []
        let signalProfilesList: any = []

        try {
            const getManufacturersData = await getManufacturers(true)
            const getSignalProfileData = await getSignalProfiles(true)

            if (getManufacturersData && getManufacturersData.length !== 0) {
                manufacturers = getManufacturersData.filter(
                    (manufacturer) => manufacturer.manufacturerType === 'boat'
                )

                manufacturerOptions = manufacturers.map(
                    ({ manufacturerName }) => manufacturerName
                )
            }
            if (getSignalProfileData && getSignalProfileData.length !== 0) {
                signalProfiles = getSignalProfileData
                signalProfilesList = getSignalProfileData.map(
                    ({ profileName }) => profileName
                )
            }

            setState({
                manufacturers,
                manufacturerOptions,
                signalProfiles,
                signalProfilesList,
            })
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    // this code below is for filtering signal profiles by manufacturers when it needs to be implemented.

    // const filterProfilesByManufacturerId = (manufacturer) => {
    //     if (manufacturer) {
    //         const findManufacturer = manufacturers.find(
    //             ({ manufacturerName }) => manufacturerName === manufacturer
    //         )

    //         const filteredModels = signalProfiles
    //             .filter(
    //                 (model) => model?.manufacturer._id === findManufacturer?._id
    //             )
    //             .map((model) => model.profileName)

    //         return filteredModels
    //     } else {
    //         return []
    //     }
    // }

    // useEffect(() => {
    //     const findSignalProfiles = filterProfilesByManufacturerId(manufacturer)

    //     if (findSignalProfiles) {
    //         setState({ signalProfilesList: findSignalProfiles })
    //     }
    // }, [manufacturer])

    useEffect(() => {
        getData()
    }, [])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })

    useEffect(() => {
        if (
            isFormDirty &&
            !manufacturerValidation &&
            !modelYearValidation &&
            !modelValidation &&
            !signalProfileValidation &&
            manufacturer &&
            model &&
            modelYear &&
            signalProfile.profileName
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        manufacturerValidation,
        modelYearValidation,
        modelValidation,
        signalProfileValidation,
        manufacturer,
        model,
        modelYear,
        signalProfile.profileName,
    ])

    useEffect(() => {
        if (activeManufacturer) {
            setState({
                formData: {
                    ...formData,
                    manufacturer: activeManufacturer,
                },
            })
        }
    }, [])

    return (
        <Grid container spacing={2}>
            <UniversalTagToolbar />
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                        <FormSection title="Boat Model Details">
                            <Grid item xs={12} md={12}>
                                <UniversalAutoComplete
                                    required
                                    options={manufacturerOptions}
                                    label="Boat Manufacturer"
                                    name="manufacturer"
                                    handleChange={handleChange}
                                    value={manufacturer}
                                    onBlur={validateOnBlur}
                                    error={
                                        manufacturerValidation && !manufacturer
                                    }
                                    helperText={
                                        manufacturerValidation && !manufacturer
                                            ? 'Please select a Boat Manufacturer name.'
                                            : ''
                                    }
                                    clearFunction={
                                        permissionsCheck
                                            ? false
                                            : () =>
                                                  setState({
                                                      formData: {
                                                          ...formData,
                                                          manufacturer: '',
                                                      },
                                                  })
                                    }
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    required={true}
                                    label="Model Year"
                                    name="modelYear"
                                    fullWidth
                                    onChange={handleChange}
                                    value={modelYear}
                                    onBlur={validateOnBlur}
                                    error={modelYearValidation && !modelYear}
                                    helperText={
                                        modelYearValidation && !modelYear
                                            ? 'Please enter a Enter a Model Year.'
                                            : ''
                                    }
                                    disabled={permissionsCheck}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required={true}
                                    label="Model"
                                    name="model"
                                    fullWidth
                                    onChange={handleChange}
                                    value={model}
                                    onBlur={validateOnBlur}
                                    error={modelValidation && !model}
                                    helperText={
                                        modelValidation && !model
                                            ? 'Please enter a Enter a Model.'
                                            : ''
                                    }
                                    disabled={permissionsCheck}
                                />
                            </Grid>
                        </FormSection>
                        <Grid item xs={12} md={4}>
                            <FormSection
                                title="Boat Model Image"
                                titleAlignment="left"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ height: 275, alignContent: 'center' }}
                                >
                                    <label htmlFor="raised-button-file">
                                        <Tooltip
                                            title={
                                                modelImageUrl
                                                    ? 'Replace model image'
                                                    : 'Upload Icon'
                                            }
                                        >
                                            <center>
                                                {modelImageUrl || modelImage ? (
                                                    <Button component="label">
                                                        <Card
                                                            sx={{
                                                                maxWidth: 400,
                                                            }}
                                                        >
                                                            <CardMedia
                                                                component="img"
                                                                height="200"
                                                                image={
                                                                    modelImage ||
                                                                    modelImageUrl
                                                                }
                                                                alt="Boat Model Image"
                                                            />
                                                            <VisuallyHiddenInput
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleFileSelect(
                                                                        event,
                                                                        true
                                                                    )
                                                                }
                                                                type="file"
                                                                accept="image/*"
                                                                id='htmlFor="raised-button-file'
                                                                disabled={
                                                                    permissionCheck
                                                                }
                                                            />
                                                        </Card>
                                                    </Button>
                                                ) : (
                                                    <IconButton component="label">
                                                        <DriveFolderUploadIcon
                                                            sx={{
                                                                fontSize: 40,
                                                            }}
                                                        />
                                                        <VisuallyHiddenInput
                                                            onChange={(event) =>
                                                                handleFileSelect(
                                                                    event,
                                                                    true
                                                                )
                                                            }
                                                            type="file"
                                                            accept="image/*"
                                                            id='htmlFor="raised-button-file'
                                                            disabled={
                                                                permissionCheck
                                                            }
                                                        />
                                                    </IconButton>
                                                )}
                                            </center>
                                        </Tooltip>
                                    </label>
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormSection
                                title="Boat Manuals"
                                titleAlignment="left"
                            >
                                <Grid item xs={12} sx={{ height: 275 }}>
                                    <label htmlFor="raised-button-file">
                                        <center>
                                            {modelOwnersManualUrl ? (
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                        }}
                                                        color="primary"
                                                        gutterBottom
                                                    >
                                                        Uploaded File:
                                                        {modelOwnerManualFileName ||
                                                            modelOwnersManualUrl.substring(
                                                                58,
                                                                modelOwnersManualUrl.length -
                                                                    1
                                                            )}
                                                    </Typography>
                                                    <Tooltip
                                                        title={
                                                            modelOwnersManualUrl
                                                                ? 'Replace Manual'
                                                                : 'Upload Manual'
                                                        }
                                                    >
                                                        <Button component="label">
                                                            <Card
                                                                sx={{
                                                                    maxWidth: 345,
                                                                }}
                                                            >
                                                                <PictureAsPdfIcon
                                                                    sx={{
                                                                        fontSize: 150,
                                                                    }}
                                                                />
                                                                <VisuallyHiddenInput
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        handleFileSelect(
                                                                            event
                                                                        )
                                                                    }
                                                                    type="file"
                                                                    accept=".doc,.docx,application/pdf"
                                                                    id='htmlFor="raised-button-file'
                                                                    disabled={
                                                                        permissionCheck
                                                                    }
                                                                />
                                                            </Card>
                                                        </Button>
                                                    </Tooltip>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                            setState({
                                                                openManual:
                                                                    true,
                                                            })
                                                        }
                                                        disabled={
                                                            modelOwnerManualFileName ||
                                                            permissionsCheck
                                                        }
                                                        sx={{
                                                            width: '100%',
                                                            height: 55,
                                                            marginTop: 3,
                                                        }}
                                                    >
                                                        View Manual
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                        }}
                                                        color="primary"
                                                        gutterBottom
                                                    >
                                                        Uploaded File:
                                                        {modelOwnerManualFileName ||
                                                            modelOwnersManualUrl.substring(
                                                                58,
                                                                modelOwnersManualUrl.length -
                                                                    1
                                                            )}
                                                    </Typography>
                                                    <Tooltip
                                                        title={
                                                            modelOwnersManualUrl
                                                                ? 'Replace Manual'
                                                                : 'Upload Manual'
                                                        }
                                                    >
                                                        <IconButton component="label">
                                                            <DriveFolderUploadIcon
                                                                sx={{
                                                                    fontSize: 150,
                                                                }}
                                                            />
                                                            <VisuallyHiddenInput
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleFileSelect(
                                                                        event
                                                                    )
                                                                }
                                                                type="file"
                                                                accept=".doc,.docx,application/pdf"
                                                                id='htmlFor="raised-button-file'
                                                                disabled={
                                                                    permissionCheck
                                                                }
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </center>
                                    </label>
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <FormSection
                        title="Signal Profile Selector"
                        titleAlignment="left"
                    >
                        <Grid item xs={12}>
                            <UniversalAutoComplete
                                required
                                options={signalProfilesList}
                                label="Signal Profile"
                                name="signalProfile"
                                handleChange={handleChange}
                                value={signalProfile.profileName}
                                clearFunction={
                                    permissionsCheck
                                        ? false
                                        : () =>
                                              setState({
                                                  formData: {
                                                      ...formData,
                                                      signalProfile: {
                                                          profileName: '',
                                                      },
                                                  },
                                              })
                                }
                                error={
                                    signalProfileValidation &&
                                    !signalProfile.profileName
                                }
                                helperText={
                                    signalProfileValidation &&
                                    !signalProfile.profileName
                                        ? 'Please enter a Enter a Signal Profile.'
                                        : ''
                                }
                                disabled={permissionsCheck}
                            />
                        </Grid>
                    </FormSection>
                </Grid>
                <Grid item xs={12}>
                    <SaveButton
                        handleSubmit={handleSubmit || permissionCheck}
                        handleReset={handleReset || permissionCheck}
                        disabledSave={!isFormValid}
                        disabledReset={!isFormDirty}
                        quickStart={quickStart}
                    />
                </Grid>
                <UniversalModal
                    title="Owners' Manual"
                    open={openManual}
                    onClose={() => setState({ openManual: false })}
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 5,
                    }}
                >
                    <PDFViewer url={modelOwnersManualUrl} />
                </UniversalModal>
            </Grid>
        </Grid>
    )
}

export default BoatModelForm
