import { createContext, useContext, useReducer, useEffect } from 'react'
import ErrorPage from '../components/UniversalComponents/ErrorPage'
import Home from '../components/Home'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import OzChatBox from './OzChat/OzChatBox'
import Signals from '../components/DataHub/Signals'
import { Boats, Users, Engines, Devices, Dealers } from '../components/Admin'
import BoatRegistrationForm from '../components/Admin/Boats/BoatRegistration'
import { Analytics } from '../components/DataHub'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    Navigate,
} from 'react-router-dom'
import NavSidebarRoot from '../components/Navigation'
import {
    Home as HomeIcon,
    Group as GroupIcon,
    DeveloperBoard,
} from '@mui/icons-material/'

import { usePermissions, useAuth } from './AuthenticationContext'
import DeactivateAccount from '../components/LoginPage/DeactivateAccount'
import PatchNotes from '../components/Developer/PatchNotes'
import SystemMonitor from '../components/Developer/SystemMonitor'
import Manufacturer from '../components/Admin/Manufacturers'

const defaultNavMenuLinks = [
    {
        path: '/',
        element: <Home />,
        name: 'Home',
        errorElement: <ErrorPage />,
        icon: <HomeIcon />,
    },
    {
        path: 'users',
        name: 'Admin',
        errorElement: <ErrorPage />,
        icon: <GroupIcon />,
        superAdmin: false,
        children: [
            {
                path: 'admin/users',
                element: <Users />,
                name: 'Users',
                errorElement: <ErrorPage />,
            },
            {
                path: 'admin/devices',
                element: <Devices />,
                name: 'Devices',
                errorElement: <ErrorPage />,
            },
            {
                path: 'admin/engines',
                element: <Engines />,
                name: 'Engines',
                errorElement: <ErrorPage />,
            },

            {
                path: 'admin/boats',
                element: <Boats />,
                name: 'Boats',
                errorElement: <ErrorPage />,
            },

            {
                path: 'admin/dealers',
                element: <Dealers />,
                name: 'Dealers',
                errorElement: <ErrorPage />,
            },
            {
                path: 'admin/manufacturer',
                element: <Manufacturer restoredData={''} />,
                name: 'Manufacturer',
                errorElement: <ErrorPage />,
            },
            // {
            //     path: 'admin/boatregistration',
            //     element: <BoatRegistrationForm />,
            //     name: 'Boat Registration Form',
            //     errorElement: <ErrorPage />,
            // },
        ],
    },

    {
        path: 'Data Hub',
        element: <Home />,
        name: 'Data Hub',
        errorElement: <ErrorPage />,
        superAdmin: false,
        icon: <AutoGraphIcon />,
        children: [
            // {
            //     path: 'data-hub/tag-manager',
            //     element: <LiveSensors />,
            //     name: 'Tag Manager',
            //     errorElement: <ErrorPage />,
            // },
            {
                path: 'data-hub/signals',
                element: <Signals />,
                name: 'Signals',
                errorElement: <ErrorPage />,
            },

            {
                path: 'data-hub/analytics',
                element: <Analytics />,
                name: 'Analytics',
                errorElement: <ErrorPage />,
            },
            // {
            //     path: 'data-hub/OZ-AI-Beta',
            //     element: <OzChatBox />,
            //     name: 'OZ AI Beta',
            //     errorElement: <ErrorPage />,
            // },
        ],
    },
    {
        path: 'System',
        element: <Home />,
        name: 'System',
        errorElement: <ErrorPage />,
        icon: <DeveloperBoard />,
        superAdmin: true,
        children: [
            {
                path: 'system/patch-notes',
                element: <PatchNotes />,
                name: 'Patch Notes',
                errorElement: <ErrorPage />,
            },
            {
                path: 'system/system-monitoring',
                element: <SystemMonitor />,
                name: 'System Monitoring',
                errorElement: <ErrorPage />,
            },
        ],
    },
]

export const NavMenu = createContext({ navMenuLinks: defaultNavMenuLinks })

export const NavMenuContext = () => {
    const defaultState = {
        navMenuLinks: defaultNavMenuLinks,
    }
    const reducer = (oldState: any, newState: any) => ({
        ...oldState,
        ...newState,
    })
    const [state, setState] = useReducer(reducer, defaultState)

    const { navMenuLinks } = state

    const userPermissions = usePermissions()
    const { auth } = useAuth()
    const { authenticated } = auth

    // Checking if the user is a Super Admin or Admin
    const isSuperAdmin = userPermissions.userRole === 'Super Admin'
    const isAdmin = userPermissions.userRole === 'Admin'

    const permissions = Object.keys(userPermissions)
        .map((permission) => userPermissions[permission].read && permission)
        .filter((permission) => permission !== undefined)

    /// Filter nav on admin
    const adminPermissions = navMenuLinks[1].children?.filter((child) =>
        permissions.includes(child.name)
    )
    const clonedAdmin = { ...navMenuLinks[1], children: adminPermissions }

    ///Filter nave on data hub
    const dataHubPermissions = navMenuLinks[2].children?.filter((child) =>
        permissions.includes(child.name)
    )
    const clonedDataHub = { ...navMenuLinks[2], children: dataHubPermissions }
    const newNavMenu: any =
        isSuperAdmin || isAdmin
            ? defaultNavMenuLinks
            : [navMenuLinks[0], clonedAdmin, clonedDataHub]

    const links: JSX.Element[] = []

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                path="/"
                errorElement={<ErrorPage />}
                element={<NavSidebarRoot />}
            >
                {navMenuLinks?.map(
                    ({ path, element, name, children, superAdmin }) => {
                        if (children && children.length !== 0) {
                            children.map((child) =>
                                links.push(
                                    <Route
                                        path={child.path}
                                        key={child.name}
                                        element={
                                            isSuperAdmin && superAdmin ? (
                                                child.element
                                            ) : permissions.includes(
                                                  child.name
                                              ) ||
                                              isSuperAdmin ||
                                              (isAdmin && !superAdmin) ? (
                                                child.element
                                            ) : (
                                                <Navigate to="/error" />
                                            )
                                        }
                                    />
                                )
                            )
                        } else {
                            links.push(
                                <Route
                                    path={path}
                                    key={name}
                                    element={element}
                                />
                            )
                        }
                        links.push(
                            <Route
                                path="deactivate-account"
                                key="deactivate-account-key"
                                element={<DeactivateAccount />}
                            />
                        )
                        return links
                    }
                )}
            </Route>
        )
    )

    useEffect(() => {
        if (authenticated) {
            setState({ navMenuLinks: newNavMenu })
        }
    }, [authenticated])

    return (
        <NavMenu.Provider value={{ navMenuLinks: newNavMenu }}>
            <RouterProvider router={router} />
        </NavMenu.Provider>
    )
}

export const useNavMenu = () => useContext(NavMenu)
