import {
    Grid,
    Typography,
    TextField,
    Autocomplete,
    IconButton,
    Tooltip,
    styled,
    Avatar,
    Box,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useContext, useEffect, useReducer, useState } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { addSignal, getSignalList } from '../../../../apiRoutes'
import {
    FormSection,
    Autocomplete as UniversalAutocomplete,
    SaveButton,
} from '../../../UniversalComponents'
import { getMeasurement } from '../../../../apiRoutes'
import { useAuth } from '../../../../context/AuthenticationContext'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { parseFormWithFile } from '../../../../utilities/functionTools'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'
const SignalManagerForm = ({
    row,
    formDescription,
    onClose,
    saveCache,
    signals,
    permissionCheck,
}) => {
    const currentUser = useAuth()
    const { username, user } = currentUser.auth
    const setToastMessage = useContext(ToastMessage)
    const defaultState = {
        formData: {
            id: row.id || '',
            signalName: row.signalName || '',
            signalFriendlyName: row.signalFriendlyName || '',
            unitOfMeasurementId: row.unitOfMeasurementId || '',
            updatedBy: row.updateBy || username,
            signalIconUrl: row.signalIconUrl || '',
            universalSignalListId: row.universalSignalListId || '',
            activeWeb: row.id ? row.activeWeb : true,
            activeMobile: row.id ? row.activeMobile : false,
            unitOfMeasurement: row.unitOfMeasurement || '',
            signalPersists: row.id ? row.signalPersists : false,
            manufacturer: row.manufacturer ? row.manufacturer : '',
        },
        formValidation: {
            signalFriendlyNameValidation: false,
            signalNameValidation: false,
            unitOfMeasurementValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        selectedFiles: [],
        setSelectedFiles: [],
        selectedOwnerEmails: [],
        setSelectedOwnerEmails: [],
        modifiedBy: row.modifiedBy || '',
        measurement: [],
        measurementOptions: [],
        signalList: [],
        permissionsCheck: permissionCheck,
        icon: '',
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        selectedFiles,
        measurement,
        measurementOptions,
        modifiedBy,
        signalList,
        icon,
    } = state

    const {
        id,
        signalFriendlyName,
        unitOfMeasurement,
        unitOfMeasurementId,
        updatedBy,
        signalIconUrl,
        universalSignalListId,
        signalName,
        activeWeb,
        activeMobile,
        signalPersists,
    } = formData

    const {
        signalFriendlyNameValidation,
        signalNameValidation,
        unitOfMeasurementValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        const isFieldDirty = formData[name] !== value

        if (name === 'unitOfMeasurement') {
            const newMeasurementId = measurement.find(
                (type) => type.unitOfMeasurement === value
            )
            setState({
                formData: {
                    ...formData,
                    unitOfMeasurementId: newMeasurementId
                        ? newMeasurementId._id
                        : '',
                    unitOfMeasurement: value,
                },
            })
        } else if (name === 'signalName') {
            if (value) {
                setState({
                    formData: {
                        ...formData,
                        signalName: value.label,
                        universalSignalListId: value.id,
                    },
                })
            } else {
                setState({
                    formData: {
                        ...formData,
                        signalName: '',
                        universalSignalListId: '',
                    },
                })
            }
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }
        return saveCache({ ...formData, [name]: value })
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
            icon: defaultState.icon,
        })

    const handleSubmit = async () => {
        try {
            const newFormData = {
                ...formData,
                unitOfMeasurement: unitOfMeasurementId,
                universalSignalList: universalSignalListId,
                manufacturer: user?.activeManufacturer?._id,
            }
            delete newFormData.signalName

            const parsedFormData = parseFormWithFile(newFormData, selectedFiles)

            let res: any

            res = await addSignal(parsedFormData)

            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Signal: ${data.signalFriendlyName} was added successfully`,
                        'success'
                    )

                    onClose(row.signalFriendlyName, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const getMeasurementData = async () => {
        try {
            const measurement = await getMeasurement(true)
            if (measurement && measurement.length !== 0) {
                setState({
                    measurementOptions: measurement.map(
                        ({ unitOfMeasurement }) => unitOfMeasurement
                    ),
                    measurement,
                })
                if (row.unitOfMeasurementId) {
                    const findMeasurement = measurement.find(
                        (measurement) =>
                            measurement.id === row.unitOfMeasurementId
                    )

                    if (findMeasurement) {
                        const { unitOfMeasurement } = findMeasurement
                        setState({
                            formData: {
                                ...formData,
                                unitOfMeasurement: unitOfMeasurement,
                            },
                        })
                    }
                }
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const getFilteredSignalList = async () => {
        const signalList = await getSignalList()

        const filteredSignalList: any = []

        if (signalList && signalList.length !== 0) {
            signalList?.forEach((signal) => {
                if (
                    signals.filter(
                        (item) => item.signalName === signal.signalName
                    ).length === 0
                ) {
                    const newSignal = {
                        id: signal._id,
                        label: signal.signalName,
                    }
                    filteredSignalList.push(newSignal)
                    return filteredSignalList
                }
            })

            setState({
                signalList:
                    filteredSignalList.length !== 0 ? filteredSignalList : [],
            })
        }
    }

    useEffect(() => {
        getMeasurementData()
        getFilteredSignalList()
        saveCache(row)
    }, [])

    const handleFileSelect = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            const cleanedFileName = file.name.replaceAll(' ', '')
            const modifiedFile = new File([file], cleanedFileName, {
                type: file.type,
            })

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }
            reader(file, (err, res) => {
                setState({
                    icon: res,
                    selectedFiles: [modifiedFile],
                    formValidation: { ...formValidation, isFormDirty: true },
                })
            })
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })

    useEffect(() => {
        if (
            isFormDirty &&
            !signalFriendlyNameValidation &&
            !signalNameValidation &&
            !unitOfMeasurementValidation &&
            signalFriendlyName &&
            signalName &&
            unitOfMeasurement
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        signalFriendlyNameValidation,
        signalNameValidation,
        unitOfMeasurementValidation,
        signalFriendlyName,
        signalName,
        unitOfMeasurement,
    ])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 10, marginBottom: -5 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {formDescription}
                </Typography>
            </Grid>

            <UniversalTagToolbar />

            <Grid
                container
                spacing={2}
                sx={{ marginTop: -5, marginBottom: -1 }}
            >
                <Grid item xs={12} md={9}>
                    <FormSection title="Signal Values" titleAlignment="left">
                        <Grid item xs={12}>
                            <UniversalAutocomplete
                                options={signalList}
                                value={signalName}
                                handleChange={handleChange}
                                required={true}
                                label="Reported Signal Name"
                                name="signalName"
                                disabled={
                                    row.universalSignalListId
                                        ? true
                                        : false && permissionCheck
                                }
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={signalNameValidation}
                                helperText={
                                    signalNameValidation
                                        ? 'Please enter  a signal name.'
                                        : ''
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required={true}
                                name="signalFriendlyName"
                                label="Customized Display Name"
                                fullWidth
                                value={signalFriendlyName}
                                onChange={handleChange}
                                disabled={permissionCheck}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={signalFriendlyNameValidation}
                                helperText={
                                    signalFriendlyNameValidation
                                        ? 'Please signal friendly name.'
                                        : ''
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                fullWidth
                                options={measurementOptions}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                    const data = {
                                        target: {
                                            name: 'unitOfMeasurement',
                                            value:
                                                typeof newValue === 'string'
                                                    ? newValue
                                                    : '',
                                        },
                                    }
                                    handleChange(data)
                                }}
                                disabled={permissionCheck}
                                value={unitOfMeasurement}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required={true}
                                        name="unitOfMeasurement"
                                        value={unitOfMeasurement}
                                        fullWidth
                                        label="Unit of Measurement"
                                        onChange={handleChange}
                                        disabled={permissionCheck}
                                        onBlur={(e: any) =>
                                            basicValidator(
                                                e,
                                                formValidation,
                                                setState
                                            )
                                        }
                                        error={unitOfMeasurementValidation}
                                        helperText={
                                            unitOfMeasurementValidation
                                                ? 'Please enter a unit of measurement.'
                                                : ''
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </FormSection>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                        <FormSection
                            title="Signal Icon Uploader"
                            titleAlignment="center"
                        >
                            <Grid item xs={12}>
                                <label htmlFor="raised-button-file">
                                    <Tooltip
                                        title={
                                            signalIconUrl
                                                ? 'Replace Default Icon'
                                                : 'Upload Icon'
                                        }
                                    >
                                        <center>
                                            {signalIconUrl || icon ? (
                                                <IconButton component="label">
                                                    <Box>
                                                        <Avatar
                                                            src={
                                                                icon ||
                                                                signalIconUrl
                                                            }
                                                            sx={{
                                                                width: 100,
                                                                height: 100,
                                                            }}
                                                        />
                                                        <VisuallyHiddenInput
                                                            onChange={(event) =>
                                                                handleFileSelect(
                                                                    event
                                                                )
                                                            }
                                                            type="file"
                                                            accept="image/*"
                                                            id='htmlFor="raised-button-file'
                                                            disabled={
                                                                permissionCheck
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                            ) : (
                                                <IconButton component="label">
                                                    <DriveFolderUploadIcon
                                                        sx={{
                                                            fontSize: 40,
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        onChange={(event) =>
                                                            handleFileSelect(
                                                                event
                                                            )
                                                        }
                                                        type="file"
                                                        accept="image/*"
                                                        id='htmlFor="raised-button-file'
                                                        disabled={
                                                            permissionCheck
                                                        }
                                                    />
                                                </IconButton>
                                            )}
                                        </center>
                                    </Tooltip>
                                </label>
                            </Grid>
                        </FormSection>
                    </Grid>

                    <Grid item xs={12}>
                        <FormSection
                            title="Signal Modifers"
                            titleAlignment="center"
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{ marginTop: 3, padding: 1 }}
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                setState({
                                                    formData: {
                                                        ...formData,
                                                        signalPersists:
                                                            !signalPersists,
                                                    },
                                                    formValidation: {
                                                        ...formValidation,
                                                        isFormDirty: true,
                                                    },
                                                })
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                            id="switch.signalPersists"
                                            name="switch.signalPersists"
                                            checked={signalPersists}
                                            key="switch.signalPersists"
                                        />
                                    }
                                    label="Persistent Signal"
                                    key="signalPersistsb"
                                    disabled={permissionCheck}
                                />
                                <Tooltip
                                    color="primary"
                                    title={
                                        <Typography fontSize="h5">
                                            Toggling on will have the signal
                                            value persist after the device is
                                            turned off
                                        </Typography>
                                    }
                                >
                                    <HelpOutlineIcon />
                                </Tooltip>
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container sx={{ marginTop: 1 }}>
                <SaveButton
                    handleSubmit={handleSubmit || permissionCheck}
                    handleReset={handleReset || permissionCheck}
                    disabledSave={!isFormValid}
                    disabledReset={!isFormDirty}
                />
            </Grid>
        </Grid>
    )
}

export default SignalManagerForm
