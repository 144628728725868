import { useReducer, useEffect, useContext } from 'react'
import {
    UniversalDataTable,
    UniversalModal,
    UniversalToolBar,
    UniversalLoadingCircle,
    UniversalExcelExport,
    DataGridLayout,
    FormSection,
} from '../../../UniversalComponents'
import {
    Button,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import UniversalCookieCrumb from '../../../UniversalComponents/UniversalCookieCrumb'
import {
    PersonAdd as PersonAddIcon,
    Delete as DeleteIcon,
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    Restore,
} from '@mui/icons-material/'
import {
    getUsers,
    activateUsers,
    getManufacturers,
} from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import UserForm from './UserForm'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import { standardColumnMapper } from '../../../../utilities/functionTools'
import universalExcelExport from '../../../UniversalComponents/UniversalExcelExport'
import { permissionsCheck } from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'
import loginLogo from '../../../../assets/osmosis/Logos/loginLogo.png'
import { useSocket } from '../../../../context/SocketContext'

const today = new Date().toISOString().substring(0, 10)

const UserAdmin = () => {
    const currentUser = useContext(Authentication)
    const { setUser } = useAuth()
    const { username, userPreferences } = currentUser.auth

    const defaultState = {
        open: false,
        row: {},
        formCache: {},
        users: [],
        openFileNamer: false,
        usersToActivate: [],
        viewDeactivated: false,
        excelExportName: '',
        excelSelectedRows: [],
        manufacturers: [],
        userColumns: {
            userAdmin: {
                columns: [
                    {
                        field: 'userEmail',
                        headerName: 'EMAIL',
                        width: 150,
                    },
                    {
                        field: 'userFirstName',
                        headerName: 'FIRST NAME',
                        width: 150,
                    },
                    {
                        field: 'userLastName',
                        headerName: 'LAST NAME',
                        width: 150,
                    },
                    {
                        field: 'userPhoneNumber',
                        headerName: 'PHONE NUMBER',
                        width: 150,
                    },
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.users?.userAdmin?.density ||
            'standard',
    }
    const setToastMessage = useContext(ToastMessage)
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()
    const { socket } = useSocket()

    const {
        open,
        row,
        formCache,
        users,
        openFileNamer,
        excelExportName,
        usersToActivate,
        viewDeactivated,
        excelSelectedRows,
        userColumns,
        density,
    } = state
    const { userAdmin } = userColumns
    const getData = async (active) => {
        try {
            const users = await getUsers(active)

            if (users && users.length !== 0) {
                const dataMapping = standardColumnMapper(defaultColumns, users)
                setState({ users: dataMapping })
            } else {
                setState({ users: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const userColumns =
            userPreferences?.dataTableColumns?.users?.userAdmin?.columns

        if (Array.isArray(userColumns)) {
            const mapColumns = userColumns.map((col) => ({
                field: col?.field,
                headerName: col?.headerName,
                width: col?.width,
                isVisible: col?.isVisible,
            }))

            const format = {
                userAdmin: {
                    columns: mapColumns,
                },
            }

            setState({ ...state, userColumns: format })
        } else {
            console.log('No Notes Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'users',
                value: userColumns,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivation = async () => {
        try {
            let res: any
            if (usersToActivate.length !== 0) {
                const findUsersToActivate = users
                    .map((user) => {
                        if (usersToActivate.includes(user._id)) {
                            return { _id: user._id, userEmail: user?.userEmail }
                        }
                    })
                    .filter((user) => user !== undefined)

                if (!viewDeactivated) {
                    res = await activateUsers({
                        users: findUsersToActivate,
                        activate: false,
                    })
                } else {
                    res = await activateUsers({
                        users: findUsersToActivate,
                        activate: true,
                    })
                }

                if (res && res.data && res.status === 200) {
                    setToastMessage(
                        `${
                            !viewDeactivated ? 'Deactivated' : 'Activated'
                        } users: ${res.data.map(
                            (userEmail: string, index) => ` ${userEmail}`
                        )}`
                    )
                    getData(!viewDeactivated)
                }
            } else {
                setToastMessage(
                    `No users selected to ${
                        !viewDeactivated ? 'deactivate' : 'activate'
                    }`,
                    'error'
                )
            }
        } catch (error: any) {
            console.error('Error archiving record:', error)
            setToastMessage(error?.message, 'error')
        }
    }

    const setViewDeactivated = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)
        collectColumnData()
        socket.on('users', (newData) => {
            if (newData) {
                getData(!viewDeactivated)
                setToastMessage(`Users have just updated.`)
            }
        })
    }, [])

    useEffect(() => {
        if (!viewDeactivated) {
            getData(true)
        } else {
            getData(false)
        }
    }, [viewDeactivated])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.userEmail) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
        getData(true)
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.userEmail
                ? `Edit: ${formCache.userEmail}`
                : 'Add New User',
            link: 'admin/users',
            data: formCache._id ? formCache : { ...formCache, id: 'newUser' },
            id: formCache._id ? formCache._id : 'newUser',
        })
        setState({ open: false, row: defaultState.row })
    }

    const openFileNamerModal = () => setState({ openFileNamer: !openFileNamer })
    const closeFileNamerModal = () =>
        setState({ openFileNamer: !openFileNamer })
    const handleTextFieldChange = (event: any) =>
        setState({ excelExportName: event.target.value })

    const buttons = [
        {
            buttonName: 'Add New User',
            buttonIcon: <PersonAddIcon />,
            buttonFunction: setModalVisbility,
        },
        // {
        //     buttonName: 'Delete',
        //     buttonIcon: <DeleteIcon />,
        //     buttonFunction: () => {},
        // },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivation,
        },
        {
            buttonName: !viewDeactivated
                ? 'View Deactivated Users'
                : 'View Active Users',
            buttonIcon: <Restore />,
            buttonFunction: setViewDeactivated,
        },
        {
            buttonName: 'Export',
            buttonIcon: <ArrowOutwardIcon />,
            buttonFunction: openFileNamerModal,
        },
    ]

    const defaultColumns = [
        'userEmail',
        'userFirstName',
        'userLastName',
        'userPhoneNumber',
        'updatedBy',
        'activeManufacturer.manufacturerName',
    ]

    const visibleColumns = [
        'userEmail',
        'userFirstName',
        'userLastName',
        'userPhoneNumber',
        'manufacturerName',
    ]

    const customColumnNames = [
        'Email',
        'First Name',
        'Last Name',
        'Phone Number',
        'Manufacturer',
    ]

    const userPermissions = usePermissions()
    const userFormPermissions =
        userPermissions?.userRole === 'Super Admin' ||
        userPermissions?.userRole === 'Admin'

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = {
            userAdmin: { columns: format, density: setDensity },
        }

        setState({
            userColumns: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            userColumns: {
                userAdmin: {
                    ...userAdmin,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Active Users'
                                : 'Deactivated Users'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={users}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !viewDeactivated
                            ? 'No active users available.'
                            : 'No deactivated users available.'
                    }
                >
                    <UniversalDataTable
                        data={users}
                        apiDensity={density}
                        apiColumns={userColumns?.userAdmin?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(rowSelection) => {
                            setState({ usersToActivate: rowSelection })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>
            </Grid>

            <UniversalModal
                title={
                    row.id ? `User: ${row.userEmail}` : 'Configure User Account'
                }
                timeStamp={`${
                    row.userEmail
                        ? ` Modified By: ${row.updatedBy} ${
                              row.updateAt
                                  ? `| Modified Date ${row.updateAt}`
                                  : ''
                          }  `
                        : ` Created by: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <UserForm
                    row={row}
                    formDescription={
                        'Use this form to add a new user account within the Osmosis Telematics Platform'
                    }
                    onClose={(tableRow: any, newData?: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    minimizeModal={minimizeModal}
                    userFormPermissionBlockCheck={userFormPermissions}
                    userFormPermissionCheck={permissionsCheck(
                        userPermissions,
                        'Users'
                    )}
                />
            </UniversalModal>

            <UniversalModal
                title={'Download Excel File'}
                open={openFileNamer}
                onClose={closeFileNamerModal}
                fullScreen={false}
                wrapperStyle={{
                    margin: 'auto',
                    width: '80%',
                    marginTop: 5,
                }}
            >
                <FormSection title="To export this Excel file please specify a title ">
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={10} sx={{ my: 2 }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="XLXS File Name"
                                    onChange={handleTextFieldChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Tooltip title="Download Excel File">
                                    <IconButton
                                        onClick={() => {
                                            UniversalExcelExport(
                                                visibleColumns,
                                                customColumnNames,
                                                users,
                                                excelSelectedRows,
                                                `${excelExportName}.xlsx`
                                            )
                                            closeFileNamerModal()
                                        }}
                                    >
                                        <DownloadForOfflineIcon
                                            sx={{
                                                fontSize: 70,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormSection>
            </UniversalModal>
        </Grid>
    )
}

export default UserAdmin
