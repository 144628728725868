import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { Grid, Button, Paper, FormControlLabel, Switch } from '@mui/material'
import { UniversalAutoComplete } from '../../../UniversalComponents/UniversalFormStyles'

interface AnalyticsFilterProps {
    startDate: any
    endDate: any
    telemetryType: string
    telemetryOptions: string[]
    fullHin?: string
    fullHinOptions: any
    setFilter: (e: any) => void
    handleSubmit: (e: any) => void
    datagridView: boolean
}
const AnalyticsFilter = ({
    startDate,
    endDate,
    telemetryType,
    telemetryOptions,
    fullHin,
    fullHinOptions,
    setFilter,
    handleSubmit,
    datagridView,
}: AnalyticsFilterProps) => {
    return (
        <Paper sx={{ padding: 5 }} elevation={4}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Date"
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            value={startDate}
                            onChange={({ $d }) => {
                                const event = {
                                    target: {
                                        value: $d,
                                        name: 'startDate',
                                    },
                                }
                                setFilter(event)
                            }}
                            slotProps={{
                                textField: {
                                    error: false,
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="End Date"
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            value={endDate}
                            onChange={({ $d }) => {
                                const event = {
                                    target: {
                                        value: $d,
                                        name: 'endDate',
                                    },
                                }
                                setFilter(event)
                            }}
                            slotProps={{
                                textField: {
                                    error: false,
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                    <UniversalAutoComplete
                        options={telemetryOptions}
                        handleChange={setFilter}
                        value={telemetryType}
                        fullWidth
                        label="Telemetry Type"
                        name="telemetryType"
                        disableClearable={true}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalAutoComplete
                        options={fullHinOptions}
                        handleChange={setFilter}
                        value={fullHin}
                        fullWidth
                        label="Generate Report By Hin"
                        name="boatId"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={setFilter}
                                inputProps={{
                                    'aria-label': 'controlled',
                                }}
                                id="datagridView"
                                name="datagridView"
                                checked={datagridView}
                                key="switch.datagridView"
                            />
                        }
                        label="Datagrid View"
                        key="switch.datagridView"
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{
                            height: 55,
                        }}
                    >
                        Generate New Report
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default AnalyticsFilter
